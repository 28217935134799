import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { userByOrganizationId } from '@/graphql/queries';

export const user = {
    namespaced: true,
    state: { 
        users: [],
    },
    mutations: {
        setUsers(state, payload) {
            state.users = payload;
        }
    },
    actions: {
        async callApiGettingUsers({commit}, variables) {
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            const resUsers = await API.graphql({
                query: userByOrganizationId,
                variables: variables,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });
            commit("setUsers", resUsers.data.userByOrganizationId.items);
            return resUsers.data.userByOrganizationId.items
        }
    },
    getters: {
        getUsers: (state) => state.users
    }
}