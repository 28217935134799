<template>
    <LoginLayout>
        <ForgotForm />
    </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue';
import ForgotForm from '@/components/ForgotForm.vue';
export default {
    components: {
        LoginLayout,
        ForgotForm
    },
}
</script>