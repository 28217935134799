import moment from 'moment-timezone'
export default {
    data() {
        return {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    },
    methods: {
        /**
         * 
         * @param Datetime UTC(0) dateTime
         * @returns String
         */
        getDatetimeFormat(dateTime) {
            const currentDate = new Date()
            const date = new Date(dateTime)

            if (currentDate.toDateString() === date.toDateString()) {
                return 'HH:mm'
            }
      
            if (currentDate.getFullYear() - date.getFullYear() > 0) {
                return 'YYYY/MM/DD'
            }
      
            return 'MM/DD'
        },

        /**
         * 
         * @param Datetime UTC(0) dateTime
         * @param String format YYYY/MM/DD, ...
         * @returns Datetime
         */
        convertTimezone(dateTime, format = '') {
            return moment.tz(dateTime, this.timezone).format(format)
        },

        /**
         * 
         * @param Datetime TZ dateTime
         * @param String format YYYY/MM/DD, ...
         * @returns Datetime
         */
        convertToUTC(dateTime, format = '') {
            return moment.utc(dateTime).format(format)
        },
    },
}