<template>
  <v-dialog
    v-model="showDialog"
    @click:outside="closeDialog()"
    class="dialog-width"
  >
    <v-card>
      <!-- Header -->
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="ml-title-card">チャット編集</v-toolbar-title>
      </v-toolbar>
      <!-- Body -->
      <v-card-text>
        <v-container class="d-none-input-form">
          <v-row>
            <v-col cols="12">
              <v-select 
                :items="selectedknowledges"
                :menu-props="menuProps"
                item-title="name"
                item-value="id"
                v-model="knowledgeId"
                label="ナレッジ"
                variant="outlined"
                density="compact"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="タイトル"
                name="title"
                variant="outlined"
                density="compact"
                v-model="title" />
              <p v-for="error of v$.title.$errors" :key="error.$uid">
                <span class="text-error">{{ error.$message }}</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="チャット AI"
                variant="outlined"
                v-model="aiType"
                density="compact"
                disabled />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="モデル"
                variant="outlined"
                v-model="aiModel"
                density="compact"
                disabled />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                label="共有"
                density="compact"
                hide-details="auto"
                v-model="isSharedBoolean" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-slider
                label="回答精度"
                v-model="temperature"
                :max="1"
                :min="0"
                :step="0.1"
                thumb-label="always"
                hide-details="auto"
              >
            </v-slider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-checkbox
                label="AIロール"
                density="compact"
                hide-details="auto"
                v-model="hasAiRoleBoolean">
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="aiRoleContent"
                label="AIロールコンテンツ"
                variant="outlined"
                rows="3"
                :disabled="!hasAiRoleBoolean">
              </v-textarea>
              <p class="text-error">{{ aiRoleContentError.message }}</p>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>
      <!-- Footer -->
      <v-divider></v-divider>
      <v-card-actions class="justify-center my-3">
        <v-btn class="btn-dialog" variant="elevated" color="primary" @click="updateChat()">
          更新
        </v-btn>
        <v-btn class="btn-dialog" variant="elevated" @click="closeDialog()">
          キャンセル
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { updateChat } from '@/graphql/mutations';
import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, helpers } from "@vuelidate/validators";

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    chat: {
      type: Object,
      default: null
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      submitted: false,
      id: '',
      ownerId: '',
      ownerName: '',
      organizationId: '',
      knowledgeId: '',
      initKnowledgeId: '',
      title: '',
      isSharedBoolean: false,
      aiType: '',
      aiModel: '',
      temperature: 0.7,
      hasAiRoleBoolean: false,
      initHasAiRole: false,
      aiRoleContent: '',
      initAiRoleContent: '',
      createdAt: '',
      menuProps: { maxWidth: '448px' },
      aiRoleContentError: {},
    }
  },

  computed: {
    showDialog() {
      return this.active;
    },
    selectedknowledges () {
      const knowledges = [];
      this.$store.getters['knowledge/getKnowledges'].forEach(knowledge => {
        knowledges.push({
          id: knowledge.id,
          name: knowledge.name
        })
      });
      return knowledges
    },
    activeChat() {
      return this.$store.state.chat.activeChat;
    }
  },

  watch: { 
    $props: {
      handler() {
        this.id = this.chat.id;
        this.ownerId = this.chat.ownerId;
        this.ownerName = this.chat.ownerName;
        this.organizationId = this.chat.organizationId;
        this.knowledgeId = this.chat.knowledgeId;
        this.initKnowledgeId = this.chat.knowledgeId;
        this.title = this.chat.title;
        this.isSharedBoolean = this.chat.isShared === 'true' ? true : false;
        this.aiType = this.chat.aiType;
        this.aiModel = this.chat.aiModel;
        this.temperature = this.chat.temperature;
        this.hasAiRoleBoolean = this.chat.hasAiRole === 'true' ? true : false;
        this.initHasAiRole = this.hasAiRoleBoolean;
        this.aiRoleContent = this.chat.aiRoleContent;
        this.initAiRoleContent = this.chat.aiRoleContent;
        this.createdAt = this.chat.createdAt;
      },
      deep: true,
      immediate: true,
    },
    knowledgeId: {
      handler(newValue, oldValue) {
        if (oldValue != undefined && newValue != oldValue) {
          const newKnowledge = this.$store.getters['knowledge/getKnowledges'].find(
            (knowledge) => knowledge.id === newValue
          );
          this.aiModel = newKnowledge.aiModel;
          this.temperature = newKnowledge.temperature;
          this.hasAiRoleBoolean = newKnowledge.hasAiRole === 'true' ? true : false;
          this.aiRoleContent = newKnowledge.aiRoleContent;
          // this.isSharedBoolean = newKnowledge.isShared === 'true' ? true : false; // Remove by issue 143
        }
      }
    }
  },

  validations() {
    return {
      title: {
        required: helpers.withMessage('必ず入力してください。', required), 
        maxLength: helpers.withMessage(
          () => `60桁以内で入力してください。`, maxLength(60)
        )
      }
    }
  },

  methods: {
    closeDialog () {
      this.v$.$reset();
      this.$emit("close");
      this.aiRoleContentError = {};
    },

    async updateChat() {
      this.submitted = true;
      console.log('Update chat')
      const result = await this.v$.$validate();
      if (!result) {
        console.log('Invalid input');
        this.submitted = false;
        return;
      }
      //check string length by aiModel
      let checkedLength = 10000;
      if (this.activeChat.aiModel == 'gpt-3.5-turbo' || this.activeChat.aiModel == 'gpt-3.5') {
        checkedLength = 1500;
      } else if (this.activeChat.aiModel == 'gpt-4') {
        checkedLength = 3000;
      } else if (this.activeChat.aiModel == 'gpt-3.5-turbo-16k') {
        checkedLength = 6000;
      } else if (this.activeChat.aiModel == 'gpt-4-turbo') {
        checkedLength = 50000;
      }

      if (this.aiRoleContent.length > checkedLength) {
        this.submitted = false;
        this.aiRoleContentError = {
          "error": "aiRoleContent",
          "message": checkedLength + "桁以内で入力してください。" 
        };
        return this.aiRoleContentError;
      }

      this.aiRoleContent = this.aiRoleContent !== null ? this.aiRoleContent : '';
      const { id, ownerId, ownerName, organizationId, knowledgeId, title, isSharedBoolean, aiType, aiModel, temperature, hasAiRoleBoolean, aiRoleContent, createdAt } = this;
      const isShared = isSharedBoolean ? 'true' : 'false';
      const hasAiRole = hasAiRoleBoolean ? 'true' : 'false';
      const isReload = this.knowledgeId !== this.initKnowledgeId ? true : false;
      const isUpdateActiveChat = this.initHasAiRole !== this.hasAiRoleBoolean || this.initAiRoleContent !== this.aiRoleContent ? true : false;
      // Update
      const updatedChatInput = { 
        id,
        ownerId,
        ownerName,
        organizationId,
        knowledgeId,
        title,
        isShared,
        aiType,
        aiModel,
        temperature,
        hasAiRole,
        aiRoleContent,
        createdAt
      };
      // Get token
      const authSession = await Auth.currentSession();
      const jwtToken = authSession.getIdToken().getJwtToken();
      try {
        const response = await API.graphql({
          query: updateChat,
          variables: { input: updatedChatInput },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          authToken: jwtToken
        })
        if (response.data.updateChat && isReload) {
          // Update ActiveChat
          this.$store.commit('chat/setActiveChat', response.data.updateChat);
          // Update activeKnowledge
          const newKnowledge = this.$store.getters['knowledge/getKnowledges'].find(
            (knowledge) => knowledge.id === knowledgeId
          );
          this.$store.commit('knowledge/setActiveKnowledge', newKnowledge);
          this.$store.commit('chat/setActionChat', 'updated');
        }
        if (response.data.updateChat && !isReload) {
          this.$emit("updateChatInfo", response.data.updateChat);
        }
        if (response.data.updateChat && isUpdateActiveChat) {
          this.$store.commit('chat/setActiveChat', response.data.updateChat);
        }
        this.submitted = false;
        this.closeDialog();
      } catch (error) {
        this.$emit("mutateChatFail", 'update');
      }
    }
  }
}
</script>
