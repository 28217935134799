<template>
  <v-container>
    <v-card-title primary-title class="bd-title">チャット履歴</v-card-title>
    <SearchPage 
      v-model:modelSearch="modelSearch" 
      v-model:modelUsers="usersSelect" 
      v-model:modelDataTable="modelDataTable" 
      v-model:user="user"
      @get-chats="getChats"/>
    <MainPage 
      v-model:modelDataTable="modelDataTable" 
      v-model:modelSearch="modelSearch"
      v-model:knowledges="knowledges"
      v-model:modelUsers="users"
      @get-MessageByChatId="getMessageByChatId"
      @get-chats="getChats"
    />  
    <DialogPage
      ref="chatDialog"
      v-model:modelSearch="modelSearch"
      v-model:knowledges="knowledges"
      @mutateChatFail="showErrorPopup('copy')"
      @get-chats="getChats"/>
    <ChatPage ref="chatPageRef" v-model:modelSearch="modelSearch"/>
    <WarningDialog
      :active="showWarningDialog"
      @close="closeWarningDialog()"
      :content="contentWarning">
    </WarningDialog>
</v-container>
</template>
<script>
import { ref } from 'vue'
import SearchPage from '@/components/chat-histories/SearchPage.vue'
import MainPage from '@/components/chat-histories/MainPage.vue'
import DialogPage from '@/components/chat-histories/DialogPage.vue'
import WarningDialog from '@/components/dialog/WarningDialog.vue'
import ChatPage from '@/components/chat-histories/ChatPage.vue'
import { mapActions } from 'vuex'
import Chat from '@/mixins/Chat.js'
import { TimestreamQuery } from 'aws-sdk'

export default {
  mixins: [Chat],
  name: "ChatHistoryPage",
  components: {
      SearchPage,
      MainPage,
      DialogPage,
      ChatPage,
      WarningDialog,
  },
  data() {
    return {
      organizationId: this.$store.state.auth.userData.organizationId,
      modelSearch: {
        selectSearch: 'date',
        radioSearch: 'organization',
        date: ref(),
        flow: ref(['calendar', 'time']),
        startTime: ref([{ hours: 0, minutes: 0 }, { hours: 0, minutes: 0 }]),
        ownerName: null,
        messages: [],
        modalDialog: false,
        modalChatHistory: false,
        chat: {},
        knowledge: {},
      },
      knowledges: [],
      modelDataTable: {
        sortBy: [{ key: 'createdAt', order: 'asc' }],
        sortDir: 'ASC',
        pageStatus: {
          currentPage: 1,
          maxPage: null,
          isPaginationFinish: false
        },
        itemsPerPage: 40,
        headers: [
          { title: "作成日時", key: "createdAt", sortable: true, width: '175'},
          { title: "タイトル", key: "title", sortable: false},
          { title: "作成者", key: "ownerId", sortable: false},
          { title: "共有", key: "isShared", align: 'center', sortable: false, width: '125'},
          { title: "id", key: "id", sortable: false, align: ' d-none'},
          { title: "knowledgeId", key: "knowledgeId", sortable: false, align: ' d-none'},
        ],
        chats: [],
        nextToken: null,
        isLoading: false,
        loadingText: "読み込み中",
        limitUser: 10
      },
      users: [],
      usersSelect: [],
      user: this.$store.getters['auth/userData'],
      showWarningDialog: false,
      contentWarning: '',
    }
  },
  async mounted() {
    try {
      this.getUsers()
      await this.$store.dispatch('knowledge/callApiGettingKnowledges')
      this.knowledges = await this.$store.getters['knowledge/getKnowledges']
      this.getChats()
    } catch (error) {
      console.log('getChats error: ', error)
      this.showErrorPopup('getChatList')
      this.modelDataTable.pageStatus.isPaginationFinish = true
      this.modelDataTable.pageStatus.maxPage = this.modelDataTable.pageStatus.currentPage
    }
  },
  methods: {
    ...mapActions({
        getChatsVue: "chat/getChats",
        getUsersVue: "user/callApiGettingUsers",
        getMessageByChatIdVue: "chatMessage/getMessageByChatId"
    }),
    async getChats() {
      let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation")
      if (isCurrentUser === 'true') {
        return this.$router.push("/login")
      }
      this.modelDataTable.isLoading = true
      try {
        let param
        let chat = []
        param = this.callApiGettingChats()
        await this.getChatsVue(param).then((res) => {
            chat = res
        })
        this.modelDataTable.chats = [...chat.items]
        this.nextToken = chat.nextToken

        if (chat.items.length !== 0) {
            this.modelDataTable.pageStatus.maxPage = Math.floor(this.modelDataTable.chats.length / this.modelDataTable.itemsPerPage)
            this.modelDataTable.pageStatus.maxPage = this.modelDataTable.chats.length % this.modelDataTable.itemsPerPage ? 
            this.modelDataTable.pageStatus.maxPage + 1 : this.modelDataTable.pageStatus.maxPage
        }
        if (this.modelDataTable.nextToken === null) {
            this.modelDataTable.pageStatus.isPaginationFinish = true
            if (this.modelDataTable.chats.length === 0 && chat.items.length === 0) this.modelDataTable.pageStatus.maxPage = 1
        }
      } catch (error) {
        console.log('getChats error: ', error)
        this.showErrorPopup('getChatList')
      }
      this.modelDataTable.isLoading = false
    },

    callApiGettingChats() {
      let param = { 
          limit: this.modelDataTable.itemsPerPage * 2,
          sortDirection: this.modelDataTable.sortDir,
          nextToken: this.modelDataTable.nextToken
        }
      let query = 'chatByOwnerId'
      let createAt = []

      if (this.modelSearch.date) {
        createAt = [ 
          this.convertToUTC(this.modelSearch.date[0], 'YYYY-MM-DDTHH:mm'),
          this.convertToUTC(this.modelSearch.date[1], 'YYYY-MM-DDTHH:mm')
        ]
      }

      if (this.modelSearch.date && this.modelSearch.radioSearch === 'user') {
        param.ownerId = this.user.id
        param.createdAt = {
          between: createAt
        }

        return { 
          'param' : param, 
          'query' : query
        }
      }

      if (this.modelSearch.radioSearch === 'user') {
        param.ownerId = this.user.id

        return { 
          'param' : param, 
          'query' : query
        }
      }
         
      if (this.modelSearch.radioSearch === 'organization' && this.modelSearch.selectSearch === 'name' && this.modelSearch.ownerName !== null) {
        param.organizationId =this.organizationId
        query = 'chatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt'
        this.user.role === 'User' ? 
        param.ownerNameIsSharedCreatedAt = { beginsWith : { ownerName: this.modelSearch.ownerName, isShared: "true" }} :
        param.ownerNameIsSharedCreatedAt = {beginsWith: { ownerName: this.modelSearch.ownerName }}

        return { 
          'param' : param, 
          'query' : query
        }
      }

      if (this.modelSearch.date && this.modelSearch.radioSearch === 'organization') {
        this.user.role == 'User' ? param.isSharedCreatedAt = {
          between: [
            {
              isShared: "true",
              createdAt: createAt[0],
            },
            {
              isShared: "true",
              createdAt: createAt[1]
            }
          ]
        } : param.createdAt = {
          between: createAt
        }
        this.user.role === 'User' ? query = 'chatByOrganizationIdAndIsShared' : query = 'chatByOrganizationId'
      } else {
        this.user.role === 'User' ? param.isSharedCreatedAt = { beginsWith : { isShared: "true" }} : ''
        query = 'chatByOrganizationIdAndIsShared'
      }
      if (this.modelSearch.radioSearch === 'organization') {
        param.organizationId = this.organizationId
        return { 
          'param' : param, 
          'query' : query
        }
      }
    },
    async getUsers() {
      let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation")
      if (isCurrentUser === 'true') {
        return this.$router.push("/login")
      }
      try {
        let param = {
          organizationId: this.organizationId
        }
        await this.getUsersVue(param).then((res) => {
          res.forEach((user) => {
            this.users[user.id] = user
            this.usersSelect.push(user.name)
          })
          this.usersSelect = [...new Set(this.usersSelect)]
        })
      } catch (error) {
        console.log('getUsers error: ', error)
      }
    },
    async getMessageByChatId(item) {
      let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation")
      if (isCurrentUser === 'true') {
          return this.$router.push("/login")
      }
      let param = { 
          chatId: item.id,
          sortDirection: "DESC",
          limit: this.limitUser
        }
      this.modelSearch.messages = []
      try {
        await this.getMessageByChatIdVue(param).then((res) => {
          this.modelSearch.messages = res.reverse()
        })
      } catch (error) {
        console.log('getMess error: ', error)
        this.showErrorPopup('getMess')
      }
    },
    showErrorPopup(type) {
      this.showWarningDialog = TimestreamQuery
      if (type === 'copy') {
        return this.contentWarning = 'チャット複製に失敗しました。'
      }
      if (type === 'getMess') {
        return this.contentWarning = 'メッセージデータのアクセスに失敗しました。'
      }
      if (type === 'getChatList') {
        return this.contentWarning = 'チャット履歴一覧のアクセスに失敗しました。'
      }
    },
    closeWarningDialog() {
      this.showWarningDialog = false
      this.contentWarning = ''
      this.$refs.chatDialog.closeDialog()
      this.$refs.chatPageRef.closeDialog()
    }
  }
}
</script>