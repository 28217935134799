<template>
    <div class="d-flex align-center justify-center mh">
        <v-sheet class="mx-auto w-login">
            <v-card class="mx-auto px-6 py-login">
                <v-form class="d-none-input-form" ref="form" variant="outlined" @submit.prevent="forgotPasswordSubmit">
                    <v-card-title primary-title class="title-login">パスワード設定</v-card-title>
                    <div class="pb-2 text-center">メールとして送信したコードを入力してください。</div>
                    <v-text-field type="text" v-model="code" label="コード" variant="outlined"></v-text-field>
                    <p v-if="error" class="text-error">{{ error.message }}</p>
                    <p v-for="error of v$.code.$errors" :key="error.$code">
                        <span class="text-error">{{ error.$message }}</span>
                    </p>
                    <div class="mb-form"></div>
                    <v-text-field type="password" v-model="newPassword" label="新しいパスワード" variant="outlined"></v-text-field>
                    <p v-for="error of v$.newPassword.$errors" :key="error.$newPassword">
                        <span class="text-error">{{ error.$message }}</span>
                    </p>
                    <div class="mb-form"></div>
                    <v-text-field type="password" v-model="confirmNewPassword" label="新しいパスワードを確認"
                        variant="outlined"></v-text-field>
                    <p v-for="error of v$.confirmNewPassword.$errors" :key="error.$confirmNewPassword">
                        <span class="text-error">{{ error.$message }}</span>
                    </p>
                    <div class="mb-form"></div>

                    <div class="d-flex flex-column">
                        <v-btn class="btn-login" type="submit" variant="elevated" size="large">保存</v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, minLength, maxLength, sameAs} from "@vuelidate/validators";
export default {
    data: () => ({
        code: "",
        newPassword: "",
        confirmNewPassword: "",
        error: {}
    }),
    mounted() {
        if(this.$store.state.auth.forgotEmail === null) {
            this.$router.push("/login");
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            code: {
                required: helpers.withMessage('必ず入力してください。', required)
            },
            newPassword: {
                required: helpers.withMessage('新しいパスワードを入力してください。', required),
                minLength: helpers.withMessage(
                    () => `8文字以上15文字以下入力してください。`, minLength(8)
                ),
                maxLength: helpers.withMessage(
                    () => `8文字以上15文字以下入力してください。`, maxLength(15)
                ),
                containsNumber: helpers.withMessage(
                    () => `大文字や数字が少なくとも一文字含まれるパスワードを入力してください。`,  helpers.regex(/[0-9]/)
                ),
                containsUppercase: helpers.withMessage(
                    () => `大文字や数字が少なくとも一文字含まれるパスワードを入力してください。`, helpers.regex(/[A-Z]/)
                ),
            },
            confirmNewPassword: {
                required: helpers.withMessage('必ず入力してください。', required),
                sameAsPassword: helpers.withMessage('パスワードが正しくありません。', sameAs(this.newPassword))
            },
        }
    },
    methods: {
        async forgotPasswordSubmit() {
            const result = await this.v$.$validate();
            if (!result) {
                console.log('Invalid input 111');
                return;
            }
            try {
                const { newPassword, code } = this;
                await Auth.forgotPasswordSubmit(this.$store.state.auth.forgotEmail, code, newPassword);
                this.$store.commit('auth/setForgotEmail', null);
                this.$router.push("/login");
            } catch (error) {
                return this.error = {
                    "error": "CodeMismatchException",
                    "message": "コードが正しくありません。"
                };
            }
        }
    },
}
</script>