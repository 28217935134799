<template>
    <v-layout>
        <v-app-bar>
          <CompanyLogo/>
          <NavDefault/>
        </v-app-bar>
        <v-main>
            <slot></slot>
        </v-main>
    </v-layout>
</template>

<script>
// Components
import NavDefault from '@/components/defaults/NavDefault.vue';
import CompanyLogo from '@/components/defaults/CompanyLogo.vue';
export default {
  name: 'HomeView',
  components: {
    NavDefault,
    CompanyLogo,
  },
  data () {
      return {
        drawer: true,
        rail: true,
      }
    },
}
</script>

<style lang="scss" scoped>
.v-app-bar {
  background: rgba(var(--v-theme-primary));
  color: #fff;
  z-index: 1000 !important;
}
</style>