<template>
    <v-container>
        <v-card-title primary-title class="bd-title">組織統計</v-card-title>
        <div class="pd-content">
            <div class="v-row">
                <div class="v-col-lg-2 v-col-4">
                    <VueDatePicker
                        v-model="year"
                        year-picker
                        position="left"
                        auto-apply
                        :clearable="false"
                        :format-locale="ja"
                        format="yo"
                        :max-date="new Date()"
                        :year-range="[2020, (new Date()).getFullYear() + 10]"
                        mode-height="120"
                        class="usage-date-picker"
                        @update:model-value="handleYearUpdated()"
                    />
                </div>
                <div class="v-col-lg-2 v-col-4">
                    <v-select
                        v-model="selectModel"
                        :items="selectModels"
                        item-title="state"
                        item-value="value"
                        @update:modelValue="getCompanyMonthlyUsage(false)"
                        variant="outlined"
                        density="compact">
                    </v-select>
                </div>
            </div>
            <div class="chart-container">
                <div class="chart-area-container">
                    <canvas id="usage-chart"></canvas>
                </div>
            </div>
        </div>
        <v-card-title primary-title class="bd-title">ユーザー統計</v-card-title>
        <div class="pd-content">
            <div class="v-row">
                <div class="v-col-lg-2 v-col-4">
                    <VueDatePicker
                        v-model="monthYear"
                        month-picker
                        position="left"
                        :teleport="true"
                        auto-apply
                        :format-locale="ja"
                        format="yo/Mo"
                        :max-date="new Date()"
                        :year-range="[2020, (new Date()).getFullYear() + 10]"
                        :clearable="false"
                        disable-year-select
                        class="usage-date-picker"
                        mode-height="190"
                        @update:modelValue="renewTableData()"
                    />
                </div>
                <div class="v-col-lg-2 v-col-4">
                    <v-select
                        v-model="selectSearch"
                        :items="selectItems"
                        item-title="state"
                        item-value="value"
                        variant="outlined"
                        density="compact"
                        @update:modelValue="handleConditionSelectorUpdated()">
                    </v-select>
                </div>
                <div class="v-col-lg-3 v-col-4">
                    <v-text-field
                        variant="outlined"
                        density="compact"
                        v-model="textForSearch"
                        @keydown="handleTextInput()">
                    </v-text-field>
                </div>
            </div>
            
            <v-data-table
                v-model:page="pageStatus.currentPage"
                v-model:sort-by="sortBy"
                :headers="headerUsers"
                :items="infoUsers"
                :items-per-page="itemsPerPage"
                :no-data-text="noResultsText"
                :loading="isLoading"
                hide-default-footer
                hover
                @update:sort-by="sortTable"
                class="usage-management-table elevation-1" style="padding-bottom: 20px;">
                <template #loading>
                    <p>{{loadingText}}</p>
                </template>
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.columns.id }}</td>
                        <td>{{ item.columns.name }}</td>
                        <td v-for="model in selectModels" :key="model.value">
                            {{ item.columns[model.value] }}
                        </td>
                    </tr>
                </template>

                <template v-slot:bottom>
                    <PaginationComponent
                        @changePage="changPage"
                        :pageStatus="pageStatus"/>
                </template>
            </v-data-table>
        </div>
        <WarningDialog 
            :active="showWarningDialog" 
            @close="closeDialog()"
            :content="contentWarning" />
    </v-container>
</template>

<script>
import { ref } from 'vue';
import { markRaw } from 'vue';
import { ja } from 'date-fns/locale';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { listByTargetIdAndTypeAndServiceAndModelAndMonth } from '@/graphql/queries';
import { listByTargetTypeAndServiceAndMonthAndID } from '@/graphql/queries';
import { listByTargetTypeAndServiceAndMonthAndOrgIdAndName } from '@/graphql/queries';
import { getMonthlyUsageDataFunction } from '@/graphql/queries';

import PaginationComponent from '@/components/defaults/PaginationComponent.vue';
import WarningDialog from '@/components/dialog/WarningDialog.vue';

export default {
    components: {
        PaginationComponent,
        WarningDialog
    },
    data() {
        return {
            companyId: 'ergo',
            targetType: {
                company: 'Organization',
                user: 'User'
            },
            targetService: 'ChatGPT',
            textForSearch: '',
            companyUsageData: '',
            chartData: [
                {name: '1月', usagedValue: 0, forecastValue: 0},
                {name: '2月', usagedValue: 0, forecastValue: 0},
                {name: '3月', usagedValue: 0, forecastValue: 0},
                {name: '4月', usagedValue: 0, forecastValue: 0},
                {name: '5月', usagedValue: 0, forecastValue: 0},
                {name: '6月', usagedValue: 0, forecastValue: 0},
                {name: '7月', usagedValue: 0, forecastValue: 0},
                {name: '8月', usagedValue: 0, forecastValue: 0},
                {name: '9月', usagedValue: 0, forecastValue: 0},
                {name: '10月', usagedValue: 0, forecastValue: 0},
                {name: '11月', usagedValue: 0, forecastValue: 0},
                {name: '12月', usagedValue: 0, forecastValue: 0},
            ],
            usageChart: '',
            organizationInfo: '',
            numberOfKnowledges: '',
            year: ref(new Date().getFullYear()),
            monthYear: ref({
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            }),
            ja: ja,
            noResultsText: "表示するデータがありません。",
            loadingText: "読み込み中",
            isLoading: false,
            selectSearch: 'id',
            selectModel: 'total',
            selectItems: [
                { state: "ID", value: "id" },
                { state: "名前", value: "name" },
            ],
            selectModels: [
                { state: "Total", value: "total" },
            ],
            headerUsers: [
                { title: 'ID', key: 'id', align: 'start' },
                { title: '名前', key: 'name' },
                { title: 'Total', key: 'total' },
            ],
            infoUsers: [],
            pageStatus: {
                currentPage: 1,
                maxPage: null,
                isPaginationFinish: false
            },
            itemsPerPage: 10,
            nextToken: null,
            inputTimeout: '',
            sortBy: [{ key: 'id', order: 'asc' }],
            sortKey: 'id',
            sortDir: 'ASC',
            filterCondition: {targetId: {contains: ''}},
            showWarningDialog: false,
            contentWarning: ''
        }
    },
    mounted() {
        this.initUsageScreenData();
    },
    methods: {
        async getCompanyMonthlyUsage(isInit = true) {
            await this.checkAuth();            
            const currentTime = new Date();
            const currentYearMonth = currentTime.getFullYear() + '-' + (currentTime.getMonth() + 1);
            this.chartData.map(row => {
                row.usagedValue = 0;
                row.forecastValue = 0;
            })
            try {
                const authSession = await Auth.currentSession()
                const jwtToken = authSession.getIdToken().getJwtToken()
                const response = await API.graphql({
                    query: listByTargetIdAndTypeAndServiceAndModelAndMonth,
                    variables: {
                        targetId: this.companyId,
                        targetTypeTargetServiceTargetModelTargetMonth: {
                            beginsWith: {
                                targetType: this.targetType.company,
                                targetService: this.targetService,
                                targetModel: this.selectModel,
                                targetMonth: String(this.year),
                            }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                    authToken: jwtToken
                })
                this.companyUsageData = response.data.listByTargetIdAndTypeAndServiceAndModelAndMonth.items;
                this.companyUsageData.forEach(element => {
                    let month = element.targetMonth.substring(5,7).replace(/^0+/, '');
                    let yearMonth = this.year + '-' + month;
                    if (month >= 1 && month <= 12) {
                        this.chartData[month - 1].usagedValue = element.value;
                    }
                    if (currentYearMonth == yearMonth) {
                        let lastAggregationDate = parseInt(element.lastAggregationDate.split('-')[2].replace(/^0+/, ''));
                        let daysInMonth = this.getDaysInMonth(this.year, month)
                        let usagePerDay =  Math.round(element.value / lastAggregationDate);
                        let forecastValue = usagePerDay * (daysInMonth - lastAggregationDate)
                        this.chartData[month - 1].forecastValue = forecastValue;
                    }
                });
            } catch (e) {
                console.log('get company usage data failed: ', e);
                this.showErrorPopup();
            }
            isInit ? this.initUsageChart() : this.updateUsageChart();
        },
        async initUsageScreenData() {
            this.organizationInfo = this.$store.state.auth.organization;
            this.numberOfKnowledges = this.organizationInfo.selectableService[0].models.length;
            this.organizationInfo.selectableService[0].models.forEach(element => {
                this.selectModels.push({'state': element, 'value': element})
                this.headerUsers.push({'title': element, 'key': element, sortable: false })
            });
            this.getCompanyMonthlyUsage();
            await this.getUserMonthlyUsage();
        },
        async getUserMonthlyUsage() {
            await this.checkAuth();
            this.isLoading = true;
            //get data from DB
            let month = this.monthYear.month + 1 < 10 ?
                '0' + (this.monthYear.month + 1) :
                this.monthYear.month + 1;
            let userUsageData = [];
            try {
                const authSession = await Auth.currentSession();
                const jwtToken = authSession.getIdToken().getJwtToken();
                do {
                    if (this.sortKey === this.headerUsers[0].key) {
                        const response = await API.graphql({
                            query: listByTargetTypeAndServiceAndMonthAndID,
                            variables: {
                                targetType: this.targetType.user,
                                targetServiceTargetMonthTargetId: {
                                    beginsWith: {
                                        targetService: this.targetService,
                                        targetMonth: this.monthYear.year + '-' + month + '-01',
                                        targetId: '/' + this.companyId + '/'
                                    }
                                },
                                filter: this.filterCondition,
                                sortDirection: this.sortDir,
                                limit: this.itemsPerPage * (this.numberOfKnowledges + 1) * 2,
                                nextToken: this.nextToken,
                            },
                            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                            authToken: jwtToken
                        });
                        response.data.listByTargetTypeAndServiceAndMonthAndID.items.forEach(e => {userUsageData.push(e)});
                        this.nextToken = response.data.listByTargetTypeAndServiceAndMonthAndID.nextToken;
                    }
                    if (this.sortKey === this.headerUsers[1].key) {
                        const response = await API.graphql({
                            query: listByTargetTypeAndServiceAndMonthAndOrgIdAndName,
                            variables: {
                                targetType: this.targetType.user,
                                targetServiceTargetMonthOrganizationIdTargetName: {
                                    beginsWith: {
                                        targetService: this.targetService,
                                        targetMonth: this.monthYear.year + '-' + month + '-01',
                                        organizationId: this.companyId,
                                        targetName: ''
                                    }
                                },
                                filter: this.filterCondition,
                                sortDirection: this.sortDir,
                                limit: this.itemsPerPage * (this.numberOfKnowledges + 1) * 2,
                                nextToken: this.nextToken
                            },
                            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                            authToken: jwtToken
                        });
                        response.data.listByTargetTypeAndServiceAndMonthAndOrgIdAndName.items.forEach(e => {userUsageData.push(e)});
                        this.nextToken = response.data.listByTargetTypeAndServiceAndMonthAndOrgIdAndName.nextToken;
                    }
                    if (this.sortKey === this.headerUsers[2].key) {
                        const monthlyUsageInput = {
                            targetType: this.targetType.user,
                            targetService: this.targetService,
                            targetMonth: this.monthYear.year + '-' + month + '-01',
                            organizationId: this.companyId,
                            targetModel: this.headerUsers[2].key,
                            selectModels: this.organizationInfo.selectableService[0].models,
                            filterConditionKey: this.filterCondition.targetId ? 'targetId' : 'targetName',
                            filterConditionValue: this.textForSearch,
                            sortDirection: this.sortDir,
                            limit: this.itemsPerPage * (this.numberOfKnowledges + 1),
                            lastEvaluatedKey: this.nextToken,
                        };
                        const result = await API.graphql({
                            query: getMonthlyUsageDataFunction,
                            variables: { input: monthlyUsageInput },
                            authToken: jwtToken
                        });
                        result.data.getMonthlyUsageDataFunction.monthlyUsage.forEach(e => {userUsageData.push(e)});
                        this.nextToken = result.data.getMonthlyUsageDataFunction.lastEvaluatedKey;
                    }
                }
                while (userUsageData.length < this.itemsPerPage * (this.numberOfKnowledges + 1) && this.nextToken !== null)
                this.isLoading = false
            } catch (e) {
                console.log('get user usage data failed: ', e);
                this.showErrorPopup();
            }

            //Handle received data
            userUsageData.forEach(e => {
                e.targetId = e.targetId.substring(e.targetId.lastIndexOf('/') + 1)
            })
            const uniqueUsers = new Set(userUsageData.map(data => data.targetId))
            this.infoUsers.forEach(user => { //remove user id if duplicate
                if (uniqueUsers.has(user.id)) {
                    uniqueUsers.delete(user.id)
                }
            })
            uniqueUsers.forEach((element) => {
                this.infoUsers.push({
                    id: element,
                    name: ''
                })
            });
            userUsageData.forEach(usageData => {
                this.infoUsers.forEach(user => {
                    if (user.id == usageData.targetId) {
                        let model = usageData.targetModel;
                        user[model] = usageData.value;
                        user.name = user.name === '' ? usageData.targetName : user.name;
                    }
                })
            })

            //Handle pagination
            if (userUsageData.length !== 0) {
                this.pageStatus.maxPage = Math.floor(this.infoUsers.length / this.itemsPerPage)
                this.pageStatus.maxPage = this.infoUsers.length % this.itemsPerPage ? this.pageStatus.maxPage + 1 : this.pageStatus.maxPage
            }
            if (this.nextToken === null) {
                this.pageStatus.isPaginationFinish = true;
                if (userUsageData.length === 0 && this.infoUsers.length === 0) this.pageStatus.maxPage = 1;
            }
        },
        updateUsageChart() {
            this.usageChart.data.datasets[0].data = this.chartData.map(row => row.usagedValue)
            this.usageChart.data.datasets[1].data = this.chartData.map(row => row.forecastValue)
            this.usageChart.update('active');
        },
        initUsageChart(){
            const usageChartContainer = document.getElementById('usage-chart');
            const chart = new Chart(
                usageChartContainer,
                {
                    type: 'bar',
                    data: {
                        labels: this.chartData.map(row => row.name),
                        datasets: [
                            {
                                label: '実績値',
                                data: this.chartData.map(row => row.usagedValue),
                                maxBarThickness: 60,
                                datalabels: {
                                    display: false,
                                }
                            },
                            {
                                label: '予測値',
                                data: this.chartData.map(row => row.forecastValue),
                                maxBarThickness: 60,
                                datalabels: {
                                    display: function(context) {
                                        if (context.chart.data.datasets[0].data[context.dataIndex]) {
                                            return true
                                        }

                                        return false
                                    },
                                    anchor: "end",
                                    align: "end",
                                    offset: "-6",
                                    labels: {
                                        title: {
                                            font: {
                                                weight: 'bold'
                                            }
                                        },
                                    }
                                }
                            },
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                                title: {
                                    display: true,
                                    text: 'Token数',
                                },
                                grace: "5%"
                            },
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: '年度統計グラフ',
                                font: {
                                    size: 16
                                },
                                padding: {
                                    bottom: 5
                                }
                            },
                            datalabels: {
                                formatter: function(value, context) {
                                    let usagedValue = context.chart.data.datasets[0].data[context.dataIndex];
                                    let forecastValue = context.chart.data.datasets[1].data[context.dataIndex];

                                    return (usagedValue + forecastValue).toLocaleString();
                                }
                            },
                            legend: {
                                onClick: function () { //prevent dataset select
                                    return false
                                },
                                labels: {
                                    filter: function(label, context) { //hidden label if dataset is empty
                                        for (let value of context.datasets[1].data) {
                                            if (value != 0) {
                                                return true
                                            }
                                        }
                                        return label.text !== '予測値'
                                    }
                                }
                            },
                        },
                    },
                    plugins: [ChartDataLabels]
                }
            );
            this.usageChart = markRaw(chart);
        },
        getDaysInMonth(year, month) {
            //Day 0 is the last day in the previous month
            return new Date(year, month, 0).getDate();
        },
        async changPage(dir) {
            if (dir === 'prev') {
                this.pageStatus.currentPage--;
                return;
            }
            if (this.pageStatus.currentPage > (this.pageStatus.maxPage - 2) && this.pageStatus.isPaginationFinish === false) {
                await this.getUserMonthlyUsage()
            }
            this.pageStatus.currentPage++;
        },
        async renewTableData() {
            this.infoUsers = [];
            this.nextToken = null;
            this.pageStatus.currentPage = 1;
            this.pageStatus.maxPage = null;
            this.pageStatus.isPaginationFinish = false;
            await this.getUserMonthlyUsage();
        },
        handleTextInput() {
            if (this.inputTimeout) clearTimeout(this.inputTimeout);
            this.inputTimeout = setTimeout(this.changeSearchCondition, 1000)
        },
        sortTable() {
            if (this.sortBy.length === 0) this.sortBy = [{ key: 'id', order: 'asc' }];
            if (this.sortBy[0].order === 'asc') this.sortDir = 'ASC';
            if (this.sortBy[0].order === 'desc') this.sortDir = 'DESC';
            this.sortKey = this.sortBy[0].key;
            this.renewTableData();
        },
        changeSearchCondition() {
            if (this.selectSearch === 'id') {
                this.filterCondition = {targetId: {contains: this.textForSearch}}
            }
            if (this.selectSearch === 'name') {
                this.filterCondition = {targetName: {contains: this.textForSearch}}
            }
            this.renewTableData();
        },
        handleYearUpdated() {
            this.getCompanyMonthlyUsage(false);
            this.monthYear.year = this.year;
            this.renewTableData();
        },
        handleConditionSelectorUpdated() {
            if (this.textForSearch === '') {
                return
            }
            this.changeSearchCondition()
        },
        showErrorPopup() {
            this.showWarningDialog = true;
            this.contentWarning = '統計データのアクセスに失敗しました。';
        },
        closeDialog() {
            this.showWarningDialog = false;
            this.contentWarning = '';
            this.isLoading = false;
        },
        async checkAuth() {
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                this.$router.push("/login");
            }
        }
    },
}
</script>