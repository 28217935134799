import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import ChatView from '../views/ChatView.vue'
import ChatHistoryView from '../views/ChatHistoryView.vue'
import ForgotView from '../views/ForgotView.vue'
import ManagementView from '../views/ManagementView.vue'
import UsageManagementView from '../views/UsageManagementView.vue'
import { Auth } from 'aws-amplify'
import store from '@/store'
import ChangePasswordView from '../views/ChangePasswordView.vue'

const routes = [
  {
    path: '/',
    name: "ChatView",
    component: ChatView,
    meta: { requiresAuth: true, title: "チャット画面" }
  },
  {
    path: '/chat-history',
    name: 'ChatHistoryView',
    component: ChatHistoryView,
    meta: { requiresAuth: true, title: "チャット履歴画面" }
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    meta: { requiresAuth: false, title: "ログイン画面" }
  },
  {
    path: "/forgot-password",
    name: "ForgotView",
    component: ForgotView,
    meta: { requiresAuth: false, title: "パスワードを忘れた場合" }
  },
  {
    path: "/management",
    name: "ManagementView",
    component: ManagementView,
    meta: { requiresAuth: true, title: "組織/ユーザ管理画面" }
  },
  {
    path: "/usage-management",
    name: "UsageManagementView",
    component: UsageManagementView,
    meta: { requiresAuth: true, title: "統計管理画面" }
  },
  {
    path: "/reset-password",
    name: "ChangePasswordView",
    component: ChangePasswordView,
    meta: { requiresAuth: false, title: "パスワードリセット画面" }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = store.state.auth.userData;
  const toName = to.name;

  // Setting page title
  document.title = to.meta.title;

  let isAuthenticated = null;
  if (currentUser) {
    isAuthenticated = await Auth.currentUserInfo();
  }

  // Redirect to login page if the user is not logged 
  if ((requiresAuth && !isAuthenticated)) {
    next("/login");
  }

  if (isAuthenticated && Object.keys(isAuthenticated).length === 0) {
    store.commit("auth/setUser", null);
    store.dispatch("auth/logout")
    next("/login");
  }

  // Redirect to homepage if the user is logged
  if (isAuthenticated && (toName === 'LoginView' || toName === 'ForgotView' || toName === 'ChangePasswordView')) {
    next("/");
  }

  if (currentUser && currentUser.role === 'User' && (toName === 'ManagementView' || toName === 'UsageManagementView')) {
    next("/");
  }

  next();
})

export default router
