<template>
    <div class="d-flex align-center justify-center mh">
        <v-sheet class="mx-auto w-login">
            <v-card class="mx-auto px-6 py-login">
                <div class="d-flex justify-center">
                    <img class="img-logo" src="logochat.png" alt="logochat.png">
                </div>
                <v-card-title primary-title class="title-login mb-3">Try ChatGPT</v-card-title>
                <v-form class="d-none-input-form" ref="form" variant="outlined" @submit.prevent="login">
                    <p class="text-error pb-2">{{ error.message }}</p>
                    <v-text-field v-model="username" type="text" label="メールアドレス" variant="outlined"></v-text-field>
                    <p v-for="error of v$.username.$errors" :key="error.$username">
                        <span class="text-error">{{ error.$message }}</span>
                    </p>
                    <div class="mb-form"></div>
                    <v-text-field v-model="password" type="password" label="パスワード" variant="outlined"></v-text-field>
                    <p v-for="error of v$.password.$errors" :key="error.$password">
                        <span class="text-error">{{ error.$message }}</span>
                    </p>
                    <div class="mb-form"></div>
                    <div class="d-flex flex-column">
                        <v-btn class="btn-login" type="submit" variant="elevated" size="large" :loading="loading">ログイン</v-btn>
                        <router-link to="/forgot-password" style="margin: auto; margin-top: 10px;">パスワード変更</router-link>
                    </div>
                </v-form>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from "@vuelidate/validators"
export default {
    data: () => ({
        username: "",
        password: "",
        email: "",
        error: {},
        loading: false
    }),
    setup() {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage('メールアドレスを入力してください。', required),
            },
            password: {
                required: helpers.withMessage('パスワードを入力してください。', required),
            },
        }
    },
    watch: {
        loading (val) {
            if (!val) return

            setTimeout(() => (this.loading = false), 4000)
        },
    },
    methods: {
        ...mapActions({
            loginVue: "auth/login"
        }),
        async login() {
            const result = await this.v$.$validate();
            if (!result) {
                console.log('Invalid input');
                return;
            }
            try {
                this.loading = !this.loading;
                let editUserName = this.username.trim(" ");
                await this.loginVue({
                    username: editUserName,
                    password: this.password,
                });
                this.$router.push("/");
            } catch (error) {
                this.loading = !this.loading;
                return this.error = {
                    "__type": "error",
                    "message": "メールアドレスまたはパスワードが正しくありません。"
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>