<template>
    <v-row>
        <v-col>
        <v-data-table
            v-model:page="dataTable.pageStatus.currentPage"
            v-model:sort-by="dataTable.sortBy"
            :headers="dataTable.headers"
            :items="dataTable.chats"
            :items-per-page="dataTable.itemsPerPage"
            :no-data-text="noResultsText"
            item-value="name"
            :loading="dataTable.isLoading"
            hide-default-footer
            hover
            class="usage-management-table elevation-1 pb-5"
            @update:sort-by="sortTable()"
        >
            <template #loading>
                <p>{{dataTable.loadingText}}</p>
            </template>
            <template v-slot:item="{ item }" >
            <tr>
                <td>{{ convertTimezone(item.columns.createdAt, 'YYYY/MM/DD HH:mm') }}</td>
                <td><span class="border-bottom-primary cursor-pointer" @click="updateModalChathistory(item.columns)">{{ item.columns.title }}</span></td>
                <td>{{ users[item.columns.ownerId]?.name }}</td>
                <td class="text-center"><v-icon v-if="item.columns.isShared == 'true'">mdi-account-group-outline</v-icon></td>
            </tr>
            </template>
            <template v-slot:bottom>
                <PaginationComponent @changePage="changPage" :pageStatus="dataTable.pageStatus"/>
            </template>
        </v-data-table>
        </v-col>
    </v-row>
</template>
  <script>
    import { useDate } from 'vuetify/labs/date'
    import PaginationComponent from '@/components/defaults/PaginationComponent.vue'
    import Chat from '@/mixins/Chat.js'
  export default {
    mixins: [Chat],
    components: {
        PaginationComponent
    },
    props: {
        modelDataTable: {
            type: Object
        },
        modelSearch: {
            type: Object
        },
        knowledges: {
            type: Array
        },
        modelUsers: {
            type: Array
        }
    },
    emits: ["get-MessageByChatId", "update:modelSearch", "get-chats", "update:modelDataTable"],
    data() {
      return {
        date : useDate(),
        search: this.modelSearch,
        dataTable: this.modelDataTable,
        noResultsText: "表示するデータがありません。",
        users: this.modelUsers
      }
    },
    methods: {
        async updateModalChathistory(item) {
            this.search.modalChatHistory = true
            this.search.chat = item
            this.knowledges.forEach((element) => {
                if(element.id == item.knowledgeId) {
                    this.search.knowledge = element
                    return
                }
            })
            this.$emit('update:modelSearch', this.search)
            this.$emit('get-MessageByChatId', item)
        },
        getChats() {
            this.$emit('update:modelSearch', this.search)
            this.$emit('get-chats')
        },
        renewTableData() {
            this.dataTable.chats = []
            this.dataTable.nextToken = null
            this.dataTable.pageStatus = {
                currentPage: 1,
                maxPage: null,
                isPaginationFinish: false
            }
            this.$emit('update:modelDataTable', this.dataTable)
            this.getChats()
        },
        sortTable() {
            if (this.dataTable.sortBy.length === 0) this.dataTable.sortBy = [{ key: 'id', order: 'asc' }]
            if (this.dataTable.sortBy[0].order === 'asc') this.dataTable.sortDir = 'ASC'
            if (this.dataTable.sortBy[0].order === 'desc') this.dataTable.sortDir = 'DESC'
            this.renewTableData()
        },
        changPage(dir) {
            if (dir === 'prev') {
                return this.dataTable.pageStatus.currentPage--
            }
            if (this.dataTable.pageStatus.currentPage > (this.dataTable.pageStatus.maxPage - 2) && this.dataTable.pageStatus.isPaginationFinish === false) {
                this.getChats()
            }
            this.dataTable.pageStatus.currentPage++
        },
    }
  }
  </script>