/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteKnowledgeFunction = /* GraphQL */ `
  mutation DeleteKnowledgeFunction($id: ID!, $uid: String!) {
    deleteKnowledgeFunction(id: $id, uid: $uid)
  }
`;
export const deleteChatFunction = /* GraphQL */ `
  mutation DeleteChatFunction($id: ID!, $uid: String!) {
    deleteChatFunction(id: $id, uid: $uid) {
      success
      errors
      __typename
    }
  }
`;
export const createUserFunction = /* GraphQL */ `
  mutation CreateUserFunction($input: CreateUserInput!) {
    createUserFunction(input: $input) {
      success
      errors
      __typename
    }
  }
`;
export const deleteUserFunction = /* GraphQL */ `
  mutation DeleteUserFunction($id: ID!) {
    deleteUserFunction(id: $id)
  }
`;
export const updateUserFunction = /* GraphQL */ `
  mutation UpdateUserFunction($input: CreateUserInput!) {
    updateUserFunction(input: $input)
  }
`;
export const copyChatFunction = /* GraphQL */ `
  mutation CopyChatFunction($input: ChatVueInput!) {
    copyChatFunction(input: $input) {
      success
      item {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      __typename
    }
  }
`;
export const postChatMessage = /* GraphQL */ `
  mutation PostChatMessage(
    $chatId: String!
    $content: String!
    $index: Int
    $role: String
    $temperature: Float
  ) {
    postChatMessage(
      chatId: $chatId
      content: $content
      index: $index
      role: $role
      temperature: $temperature
    ) {
      chatId
      message {
        id
        ownerId
        organizationId
        chatId
        index
        role
        sender
        temperature
        content
        finishReason
        createdAt
        updatedAt
        __typename
      }
      response {
        id
        ownerId
        organizationId
        chatId
        index
        role
        sender
        temperature
        content
        finishReason
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      status
      onwersEmailAddress
      quota {
        maxUsers
        maxChatEntries
        maxKnowledged
        __typename
      }
      selectableService {
        serviceId
        models
        modelDefault
        __typename
      }
      logoPath
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      status
      onwersEmailAddress
      quota {
        maxUsers
        maxChatEntries
        maxKnowledged
        __typename
      }
      selectableService {
        serviceId
        models
        modelDefault
        __typename
      }
      logoPath
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      status
      onwersEmailAddress
      quota {
        maxUsers
        maxChatEntries
        maxKnowledged
        __typename
      }
      selectableService {
        serviceId
        models
        modelDefault
        __typename
      }
      logoPath
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBlockedKeyword = /* GraphQL */ `
  mutation CreateBlockedKeyword(
    $input: CreateBlockedKeywordInput!
    $condition: ModelBlockedKeywordConditionInput
  ) {
    createBlockedKeyword(input: $input, condition: $condition) {
      id
      organizationId
      keywords
      condition
      description
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBlockedKeyword = /* GraphQL */ `
  mutation UpdateBlockedKeyword(
    $input: UpdateBlockedKeywordInput!
    $condition: ModelBlockedKeywordConditionInput
  ) {
    updateBlockedKeyword(input: $input, condition: $condition) {
      id
      organizationId
      keywords
      condition
      description
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBlockedKeyword = /* GraphQL */ `
  mutation DeleteBlockedKeyword(
    $input: DeleteBlockedKeywordInput!
    $condition: ModelBlockedKeywordConditionInput
  ) {
    deleteBlockedKeyword(input: $input, condition: $condition) {
      id
      organizationId
      keywords
      condition
      description
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      organizationId
      uid
      name
      email
      description
      status
      role
      owner
      knowledges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      organizationId
      uid
      name
      email
      description
      status
      role
      owner
      knowledges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      organizationId
      uid
      name
      email
      description
      status
      role
      owner
      knowledges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKnowledge = /* GraphQL */ `
  mutation CreateKnowledge(
    $input: CreateKnowledgeInput!
    $condition: ModelKnowledgeConditionInput
  ) {
    createKnowledge(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      name
      description
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      isShared
      isDefault
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKnowledge = /* GraphQL */ `
  mutation UpdateKnowledge(
    $input: UpdateKnowledgeInput!
    $condition: ModelKnowledgeConditionInput
  ) {
    updateKnowledge(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      name
      description
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      isShared
      isDefault
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKnowledge = /* GraphQL */ `
  mutation DeleteKnowledge(
    $input: DeleteKnowledgeInput!
    $condition: ModelKnowledgeConditionInput
  ) {
    deleteKnowledge(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      name
      description
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      isShared
      isDefault
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      knowledgeId
      ownerName
      title
      isShared
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      numOfMessages
      createdAt
      updatedAt
      postedAt
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      knowledgeId
      ownerName
      title
      isShared
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      numOfMessages
      createdAt
      updatedAt
      postedAt
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      knowledgeId
      ownerName
      title
      isShared
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      numOfMessages
      createdAt
      updatedAt
      postedAt
      __typename
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      chatId
      index
      role
      sender
      temperature
      content
      finishReason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      chatId
      index
      role
      sender
      temperature
      content
      finishReason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      ownerId
      organizationId
      chatId
      index
      role
      sender
      temperature
      content
      finishReason
      createdAt
      updatedAt
      __typename
    }
  }
`;
