<template>
    <v-dialog
      v-model="showDialog"
      @click:outside="closeDialog()"
      class="dialog-width"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title class="ml-title-card">ナレッジの{{ knowledge === null ? '新規追加' : '編集'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container class="d-none-input-form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="名前"
                    name="name"
                    variant="outlined"
                    v-model="name"
                    density="compact"
                >
                </v-text-field>
                <p v-for="error of v$.name.$errors" :key="error.$uid">
                  <span class="text-error">{{ error.$message }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="チャット AI"
                  variant="outlined"
                  v-model="aiType"
                  density="compact"
                  disabled>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="chat_models"
                  v-model="aiModel"
                  label="モデル"
                  density="compact"
                  variant="outlined">
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  label="共有"
                  density="compact"
                  hide-details="auto"
                  v-model="isSharedBoolean">
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-slider
                  label="回答精度"
                  v-model="temperature"
                  :max="1"
                  :min="0"
                  :step="0.1"
                  thumb-label="always"
                  hide-details="auto"
                >
              </v-slider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0">
                <v-checkbox
                  label="AIロール"
                  density="compact"
                  hide-details="auto"
                  v-model="hasAiRoleBoolean">
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="aiRoleContent"
                  label="AIロールコンテンツ"
                  variant="outlined"
                  rows="3"
                  :disabled="!hasAiRoleBoolean">
                </v-textarea>
                <p v-for="error of v$.aiRoleContent.$errors" :key="error.$aiRoleContent">
                  <span class="text-error">{{ error.$message }}</span>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="description"
                  label="コメント"
                  rows="3"
                  variant="outlined">
                </v-textarea>
                <p v-for="error of v$.description.$errors" :key="error.$description">
                  <span class="text-error">{{ error.$message }}</span>
                </p>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions class="justify-center my-3">
          <v-btn
            variant="elevated"
            color="primary"
            class="btn-dialog"
            @click="createKnowledge"
          >{{ knowledge === null ? '保存' : '更新'}}</v-btn>
          <v-btn
            variant="elevated"
            class="btn-dialog"
            @click="closeDialog()"
          >キャンセル</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
    import { API, Auth } from "aws-amplify";
    import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
    import { createKnowledge, updateKnowledge } from '@/graphql/mutations';
    import { useVuelidate } from '@vuelidate/core';
    import { required, maxLength, helpers } from "@vuelidate/validators";

    export default {
        props:
        {
          active: {
            type: Boolean,
            default: false
          },
          knowledge: {
            type: Object,
            default: null
          }
        },
        setup () {
          return { v$: useVuelidate() }
        },
        data () {
            return {
              chat_models: [],
              submitted: false,
              id: '',
              name: '',
              description: '',
              ownerId: '',
              organizationId: '',
              aiType: 'ChatGPT',
              aiModel: '',
              temperature: 0.7,
              hasAiRoleBoolean: false,
              aiRoleContent: '',
              isSharedBoolean: false,
              isDefaultBoolean: false
            }
            
        },
        computed: {
          showDialog() {
            return this.active;
          },
          currentUser() {
            return this.$store.state.auth.user;
          },
          currentOrganization() {
            return this.$store.state.auth.organization;
          }
        },
        mounted() {
          console.log('mounted knowledge dialog');
          console.log(this.currentOrganization);
          this.chat_models = this.currentOrganization.selectableService[0].models;
          this.setAiModelValue();
        },
        watch: { 
          $props: {
            handler() {
              console.log('Something changed');

              if ( this.knowledge !== null ) {
                this.id = this.knowledge.id;
                this.name = this.knowledge.name;
                this.description = this.knowledge.description;
                this.ownerId = this.knowledge.ownerId;
                this.organizationId = this.knowledge.organizationId;
                this.aiType = this.knowledge.aiType;
                this.aiModel = this.knowledge.aiModel;
                this.temperature = this.knowledge.temperature;
                this.hasAiRoleBoolean = this.knowledge.hasAiRole === 'true' ? true : false;
                this.aiRoleContent = this.knowledge.aiRoleContent;
                this.isSharedBoolean = this.knowledge.isShared === 'true' ? true : false;
                this.isDefaultBoolean = this.knowledge.isDefault === 'true' ? true : false;
              } else {
                this.name = '';
                this.description = '';
                this.ownerId = this.currentUser.username;
                this.organizationId = this.currentOrganization.id;
                this.aiType = 'ChatGPT';
                this.hasAiRoleBoolean = false;
                this.temperature = 0.7;
                this.aiRoleContent = '';
                this.isSharedBoolean = false;
                this.isDefaultBoolean = false;
                // this.aiModel = 'gpt3.5-turbo';
                this.setAiModelValue();
              }
            },
            deep: true,
            immediate: true,
          },
        },
        validations() {
          return {
            name: {
              required: helpers.withMessage('必ず入力してください。', required), 
              maxLength: helpers.withMessage(
                () => `60桁以内で入力してください。`, maxLength(60)
              ),
            },
            description: {
              maxLength: helpers.withMessage(
                () => `200桁以内で入力してください。`, maxLength(200)
              ),
            },
            aiRoleContent: {
              maxLength: helpers.withMessage(
                () => `1000桁以内で入力してください。`, maxLength(1000)
              ),
            }
          }
          
        },
        methods: {
            async createKnowledge() {
                this.submitted = true;
                console.log('create knowledge')
                const result = await this.v$.$validate();
                if (!result) {
                  console.log('Invalid input');
                  this.submitted = false;
                  return;
                }

                const { id, ownerId, organizationId, name, description, aiType, aiModel
                  , temperature, hasAiRoleBoolean, aiRoleContent, isSharedBoolean, isDefaultBoolean } = this;
                const isShared = isSharedBoolean ? 'true' : 'false';
                const isDefault = isDefaultBoolean ? 'true' : 'false';
                const hasAiRole = hasAiRoleBoolean ? 'true' : 'false';

                // Getting jwtToken
                const authSession = await Auth.currentSession();
                const jwtToken = authSession.getIdToken().getJwtToken();
                try {
                  if (this.knowledge === null) {
                    // Create
                    let newKnowledge = { 
                      ownerId: ownerId
                      , organizationId: organizationId
                      , name: name
                      , description: description
                      , aiType: aiType
                      , aiModel: aiModel
                      , temperature: temperature 
                      , hasAiRole: hasAiRole
                      , aiRoleContent: aiRoleContent
                      , isShared: isShared
                      , isDefault: 'false' };
  
                    const createResult = await API.graphql({
                        query: createKnowledge,
                        variables: { input: newKnowledge },
                        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                        authToken: jwtToken
                    })
                    // store add
                    this.$store.commit('knowledge/addKnowledge', createResult.data.createKnowledge);
                    // set active knowledge
                    this.$store.commit('knowledge/setActiveKnowledge', createResult.data.createKnowledge);
  
                    // update user.knowledges (DynamoDB)
                    await this.$store.dispatch('knowledge/updateUserKnowledges');
                    // update user.userData.knowledges (store)
                    await this.$store.dispatch('auth/updateUserDataKnowledges');
  
                    this.jumpToKnowledges(0);

                    this.$emit('createChatAfterKnowledge');
  
                  } else {
                    // Update
                    const knowledge = { id, ownerId, organizationId, name, description, aiType, aiModel
                      , temperature, hasAiRole, aiRoleContent, isShared, isDefault };
                    await API.graphql({
                        query: updateKnowledge,
                        variables: { input: knowledge },
                        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                        authToken: jwtToken
                    })
                    // store update
                    this.$store.commit('knowledge/updateKnowledge', knowledge);
                  }
                  this.submitted = false;
                  this.closeDialog();
                } catch (error) {
                  this.$emit("mutateKnowledgeFail", this.knowledge === null ? 'create' : 'update');
                }

            },
            setAiModelValue () {
              if (this.currentOrganization.selectableService[0].modelDefault !== "") {
                this.aiModel = this.currentOrganization.selectableService[0].modelDefault;
              } else {
                this.aiModel = this.chat_models[0];
              }
            },
            closeDialog () {
              // this.$emit("update:active", false);
              this.v$.$reset();
              this.$emit("close");
            },
            jumpToKnowledges(oldPosition) {
              this.$emit("jumpToKnowledges", oldPosition);
            }
        }
    }
</script>