<template>
    <v-dialog
          v-model="showDialog"
          max-width="576"
          class="dialog-confirm-delete"
          @click:outside="closeDialog()"
      >
        <v-card>
          <v-card-text>
            <v-container>
                <div class="d-flex justify-center pb-7">
                    <v-icon icon="mdi-information-outline" color="error" size="x-large"></v-icon>
                </div>
                <p class="text-h5 text-error text-center">
                    <slot name="dialog-title" />
                </p>
                <br>
                <p class="text-center">
                    <slot name="dialog-content" />
                </p>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-btn
              variant="elevated"
              class="btn-dialog"
              color="error"
              @click="confirmDelete"
              :loading="showLoading"
            >はい</v-btn>
            <v-btn
              variant="elevated"
              class="btn-dialog"
              @click="closeDialog"
            >キャンセル</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showDialog() {
            return this.active;
        },
        showLoading() {
            return this.isLoading;
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        closeDialog () {
            this.$emit("close");
        },
        confirmDelete() {
            this.$emit("delete");
        }
    }
}

</script>
