import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { knowledgeByOwnerId } from '@/graphql/queries';
import { updateUser } from '@/graphql/mutations';

export const knowledge = {

    namespaced: true,
    state: { 
        knowledges: [],
        activeKnowledge: null
    },
    mutations: {
        setKnowledges(state, payload) {
            state.knowledges = payload;
        },
        setActiveKnowledge(state, payload) {
            state.activeKnowledge = payload;
        },
        updateKnowledge(state, payload) {
            const updatingKnowledge = payload;
            const objIndex = state.knowledges.findIndex((obj => obj.id == updatingKnowledge.id));
            state.knowledges[objIndex] = updatingKnowledge;
        },
        addKnowledge(state, payload) {
            state.knowledges.push(payload)
        },
        removeKnowledge(state, payload) {
            const toDeleteIndex = state.knowledges.findIndex((obj) => obj.id === payload.id);
            if (toDeleteIndex > -1) {
                state.knowledges.splice(toDeleteIndex, 1);
            }
        },
        
    },
    actions: {
        // call API getting knowledges from DB
        async callApiGettingKnowledges({commit, rootState}) {
            // Getting jwtToken
            const authSession = await Auth.currentSession();
            const jwtToken = authSession.getIdToken().getJwtToken();
            // Getting knowledges
            const resKnowledges = await API.graphql({
                query: knowledgeByOwnerId,
                variables: { ownerId: rootState.auth.user.username },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });

            const sortingKeys = rootState.auth.userData.knowledges;
            let arrKnowledges = resKnowledges.data.knowledgeByOwnerId.items;
            arrKnowledges.sort((a, b) => sortingKeys.indexOf(a.id) - sortingKeys.indexOf(b.id));
            commit("setKnowledges", resKnowledges.data.knowledgeByOwnerId.items);
        },
        // call API update user.knowledges
        async updateUserKnowledges({state, rootState}) {
            console.log("updateUserKnowledges");
            const authSession = await Auth.currentSession();
            const jwtToken = authSession.getIdToken().getJwtToken();
            const arrUserKnowledgeIds = state.knowledges.map(a => a.id);
            const userInfo = { 
                id: rootState.auth.user.username, 
                knowledges: arrUserKnowledgeIds
            };
            await API.graphql({
                query: updateUser,
                variables: { input: userInfo },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            })
        }
    },
    getters: {
        getKnowledges: (state) => state.knowledges,
        getActiveKnowledge: (state) => state.activeKnowledge
    }
}