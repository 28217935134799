<template>
    <LoginLayout>
        <LoginForm />
    </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue';
import LoginForm from '@/components/LoginForm.vue';
export default {
    components: {
        LoginLayout,
        LoginForm
    },
}
</script>

<style lang="scss" scoped>

</style>