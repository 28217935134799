<template>
    <v-container>
        <v-card-title primary-title class="bd-title">組織情報</v-card-title>
        <div class="pd-content">
            <div class="v-row">
                <div class="org-title v-col-5 d-flex"><div class="w-text">ID</div>
                <p class="w-content">{{ organization.id }}</p>
            </div>
                    <div class="org-content v-col-7 d-flex"><div class="w-text">Owner</div>
                    <p class="w-content">{{ organization.onwersEmailAddress }}</p>
                </div>
            </div>
            <div class="v-row">
                <div class="org-title v-col-5 d-flex"><div class="w-text">組織名</div>
                <p class="w-content">{{ organization.name }}</p>
            </div>
                <div class="org-content v-col-7 d-flex"><div class="w-text">状態</div>
                <p class="w-content">{{ getStatusText(organization.status) }}</p>
            </div>
            </div>
            <div class="v-row">
                <div class="v-col-12">
                    <v-btn @click="toggleStatus()" variant="elevated" width="100px">
                        <v-icon :class="isDisplay ? 'mdi mdi-minus' : 'mdi mdi-plus'"></v-icon>詳細
                    </v-btn>
                </div>
            </div>
            <div v-if="isDisplay" class="v-row">
                <div class="org-title v-col-5 d-flex">
                    <div class="w-text">ユーザー数上限</div>
                    <p class="w-content">{{ quota.maxUsers }}</p>
                </div>
                <div class="org-content v-col-7 d-flex">
                    <div class="w-text">チャット数上限</div>
                    <p class="w-content">{{ quota.maxChatEntries }}/ユーザー</p>
                </div>
                <div class="org-title v-col-5 d-flex">
                    <div class="w-text">ナレッジ数上限</div>
                    <p class="w-content">{{ quota.maxKnowledged }}/ユーザー</p>
                </div>
                <div class="org-content v-col-7 d-flex">
                    <div class="w-text">選択可能モデル</div>
                    <div v-if="selectableService.length > 0">
                        <div v-for="(model, index) in organization.selectableService[0]['models']" :key="`model_` + index">{{ model ?? '' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>

export default {
    data () {
        return {
            isDisplay: true,
            organization: {},
            quota: {
                maxUsers: '',
                maxChatEntries: '',
                maxKnowledged: ''
            },
            selectableService: []
        }
    },

    mounted() {
        this.getOrganization();
    },

    computed: {
      currentUser() {
        return this.$store.state.auth.userData;
      }
    },

    methods: {
        toggleStatus() {
            this.isDisplay = !this.isDisplay;
        },

        async getOrganization() {
            this.organization = this.$store.state.auth.organization;
            this.quota = this.organization.quota;
            this.selectableService = this.organization.selectableService;
        },

        getStatusText(status) {
            if (status === 'Active') {
                return '有効';
            }

            return '無効';
        }
    }
}
</script>
