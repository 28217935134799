<template>
    <v-dialog v-model="search.modalDialog" class="dialog-add-chathistory dialog-width">
        <v-card>
            <v-toolbar color="primary" dark>
                <v-toolbar-title class="ml-title-card">チャット編集</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container class="pb-0">
                    <v-row>
                        <v-col cols="12">
                            <v-select 
                                label="ナレッジ"
                                v-model="knowledgeId"
                                :items="arrKnowledges"
                                :menu-props="menuProps"
                                item-title="name"
                                item-value="id"
                                variant="outlined" 
                                @update:modelValue="getChat()"
                                density="compact"
                                placeholder="選択してください"
                                required hide-details="auto">
                            </v-select>
                            <p v-for="error of v$.knowledgeId.$errors" :key="error.$uid">
                                <span class="text-error">{{ error.$message }}</span>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="title"
                                label="タイトル"
                                variant="outlined"
                                required
                                density="compact"
                                hide-details="auto">
                            </v-text-field>
                            <p v-for="error of v$.title.$errors" :key="error.$uid">
                                <span class="text-error">{{ error.$message }}</span>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="aiType"
                                label="チャット"
                                variant="outlined"
                                required
                                hide-details="auto"
                                density="compact"
                                disabled>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="aiModel"
                                label="Model"
                                variant="outlined"
                                required
                                hide-details="auto"
                                density="compact"
                                disabled>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-checkbox
                                variant="outlined"
                                hide-details="auto"
                                v-model="isShared"
                                density="compact"
                                label="共有">
                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                        <v-slider
                            label="回答精度"
                            v-model="temperature"
                            :max="1"
                            :min="0"
                            :step="0.1"
                            thumb-label="always"
                            hide-details="auto"
                        >
                        </v-slider>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" class="pb-0">
                        <v-checkbox
                            hide-details="auto"
                            density="compact"
                            label="AIロール"
                            v-model="hasAiRole"
                            @click="getAiRoleContent()">
                        </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                        <v-textarea
                            v-model="aiRoleContent"
                            label="AIロールコンテンツ"
                            variant="outlined"
                            rows="3"
                            :disabled="!hasAiRole">
                        </v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center my-3">
                <v-btn color="primary" variant="elevated" class="btn-copy-dialog" @click="submit()" :loading="isLoading">チャット内容をコピーし会話を続ける</v-btn>
                <v-btn class="btn-cancel" variant="elevated" @click="closeDialog()">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions } from 'vuex'
    import { useVuelidate } from '@vuelidate/core'
    import { required, maxLength, helpers } from "@vuelidate/validators"
    export default {
        props: {
            modelSearch: {
                type: Object
            },
            knowledges: {
                type: Array
            }
        },
        setup () {
          return { v$: useVuelidate() }
        },
        validations() {
          return {
            title: {
                required: helpers.withMessage('必ず入力してください。', required),
                maxLength: helpers.withMessage(
                    () => `255桁以内で入力してください。`, maxLength(255)
                ),
            },
            knowledgeId: {
                required: helpers.withMessage('必ず入力してください。', required),
            }
          }
        },
        data() {
            return {
                search: this.modelSearch,
                arrKnowledges : [],
                knowledgeId: '',
                knowledge: {},
                title: '',
                isShared: false,
                aiType: '',
                aiModel: '',
                chatId: '',
                isLoading: false,
                temperature: 0.7,
                hasAiRole: false,
                aiRoleContent: '',
                menuProps: { maxWidth: '448px' }
            }
        },
        watch: {
            $props: {
                handler() {
                    this.chatId = this.modelSearch.chat.id
                    this.title = this.modelSearch.chat.title
                    this.messages = this.modelSearch.messages
                    this.arrKnowledges = this.knowledges
                    this.knowledgeId = this.modelSearch.chat.knowledgeId
                    this.aiType = this.modelSearch.chat.aiType
                    this.aiModel = this.modelSearch.chat.aiModel
                    this.temperature = this.modelSearch.chat.temperature
                    this.hasAiRole = this.modelSearch.chat.hasAiRole
                    this.aiRoleContent = this.modelSearch.chat.aiRoleContent
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            ...mapActions({
                copyChatVue: "chat/copyChat"
            }),
            async submit() {
                let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation")
                if (isCurrentUser === 'true') {
                    return this.$router.push("/login")
                }
                this.isLoading = true
                const result = await this.v$.$validate()
                if (!result) {
                  console.log('Invalid input')
                  this.isLoading = false
                  return
                }
                let param = {
                    id: this.chatId,
                    ownerId: this.$store.state.auth.userData.id,
                    ownerName: this.$store.state.auth.userData.name,
                    organizationId: this.$store.state.auth.userData.organizationId,
                    knowledgeId: this.knowledgeId,
                    title: this.title,
                    isShared: this.isShared ? 'true' : 'false',
                    aiType: this.aiType,
                    aiModel: this.aiModel,
                    temperature: this.temperature,
                    hasAiRole: this.hasAiRole ? 'true' : 'false',
                    aiRoleContent: this.aiRoleContent
                }
                try {
                    await this.copyChatVue(param).then((res) => {
                        if (res.success) {
                            this.closeDialog()
                            this.$store.commit('chat/setActiveChat', res.item)
                            this.$store.commit('knowledge/setActiveKnowledge', this.knowledge)
                            this.$router.push({ name: 'ChatView' })
                        }
                    })
                }  catch (error) {
                    this.error = error
                    this.$emit("mutateChatFail")
                }
                this.isLoading = false
            },
            getAiRoleContent() {
                if (this.hasAiRole) {
                    this.aiRoleContent = ''
                }
            },
            getChat() {
                this.arrKnowledges.forEach(element => {
                    if (element.id == this.knowledgeId) {
                        this.aiType = element.aiType
                        this.aiModel = element.aiModel
                        this.knowledge = element
                        this.temperature = element.temperature
                        this.hasAiRole = element.hasAiRole === 'true' ? true : false
                        this.aiRoleContent = element.aiRoleContent !== null ? element.aiRoleContent : ''
                        return 
                    }
                })
            },
            closeDialog() {
                this.v$.$reset()
                this.search.modalDialog = false
                this.$emit('update:modelSearch', this.search)
                this.$emit("close")
            }
        }
    }
</script>