<template>
    <v-dialog
          v-model="showDialog"
          max-width="450"
          @click:outside="closeDialog()"
      >
        <v-card>
          <v-card-text>
            <v-container>
                <div class="d-flex justify-center pb-7">
                    <v-icon icon="mdi-alert-outline" color="warning" size="x-large"></v-icon>
                </div>
                <p class="text-center" v-html="showContent"></p>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center pb-5">
            <v-btn
              variant="elevated"
              class="btn-dialog"
              @click="closeDialog"
            >閉じる</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        },
        content: {
            type: String,
        }
    },
    computed: {
        showDialog() {
            return this.active;
        },
        showContent() {
            return this.content;
        }
    },
    data() {
        return {
        }
    },
    methods: {
        closeDialog () {
            this.$emit("close");
        }
    }
}
</script>
