<template>
    <v-layout>
        <v-main>
            <slot></slot>
        </v-main>
    </v-layout>
</template>

<script>
export default {
    components: {
    },
}
</script>

<style lang="scss" scoped>

</style>