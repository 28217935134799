<template>
  <ChatLayout v-resize="handleResizeEvent">
    <div class="chat-view">
      <div v-if="activeChat?.hasAiRole === 'true'" class="ai-role-area"><span>AIロール: {{ activeChat?.aiRoleContent }}</span></div>
      <div class="cw-wrap-messages">
        <v-infinite-scroll id="cwWrapMessagesPaging" :onLoad="loadMoreMessages" side="start" style="height: 100%;">
          <template v-for="message in messages" :key="message">
            <div
              class="cw-row-message"
              :class="(message.sender === message.ownerId || message.sender === 'system') ? 'message-type-question' : 'message-type-answer'"
            >
              <div class="message-area">
                <div class="message-area-upper">
                  <div class="cw-rm-icon">
                    <div v-if="message.sender === 'system'">
                      <v-icon icon= "mdi mdi-alert-outline" color="error" class="system-error-icon"></v-icon>
                    </div>
                    <div v-else-if="message.sender !== message.ownerId">
                      <img src="ai_icon.svg" alt="AI">
                    </div>
                    <div v-else>
                      <img src="user_icon.svg" alt="User">
                    </div>
                  </div>
    
                  <div v-if="message.sender === 'system'" class="mt-1 cw-rm-content cw-rm-content-system">{{ message.content }}</div>
                  <div v-else-if="message.sender !== message.ownerId" class="mt-1 cw-rm-content cw-rm-content-a" v-html="handleMarkedJS(message.content)"></div>
                  <div v-else class="mt-1 cw-rm-content cw-rm-content-q">
                    <div class="cw-rm-content-q-div">{{ message.content }}</div>
                    <div class="edit-question-area d-none">
                      <v-textarea
                        @keydown="handleKeyEvent($event, true, message.index, message.chatId)"
                        @blur="blurEditedQuestion($event, message)"
                        v-model="message.content"
                        density="compact"
                        variant="solo"
                        rows="1"
                        auto-grow
                        :disabled="disabledInputEditedMessage"
                        class="txt-input-edit-message"
                      >
                      </v-textarea>
                      <v-btn
                        :loading="sendEditedMsgloading"
                        :disabled="disabledInputEditedMessage"
                        density="compact"
                        icon="mdi-send-outline"
                        @mousedown="preventBlurEvent($event)"
                        @click="sendEditedQuestion($event, message.index, message.chatId)">
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="message-area-lower">
                  <div class="wrap-qa-time">
                    <div class="qa-time-text">{{ convertTimezone(message.createdAt, getDatetimeFormat(message.createdAt)) }}</div>
                    <div class="qa-time-icon">
                      <v-btn
                        v-if="message.id !== ''"
                        variant="plain"
                        density="compact"
                        class="mr-1"
                        :icon="isCopied[message.id] ? 'mdi mdi-check' : 'mdi mdi-content-copy'"
                        :disabled="sendMsgloading"
                        @click="copyMessage(message)"></v-btn>
                        <v-btn
                        v-if="message.sender === message.ownerId"
                        variant="plain"
                        density="compact"
                        icon="mdi-pencil-outline"
                        :disabled="sendMsgloading"
                        @click="editQuestion($event)"></v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:empty>
            <div></div>
          </template>
          <!-- <template v-slot:load-more="{ props }">
            <v-btn
              icon="mdi-refresh"
              variant="text"
              v-bind="props"
            ></v-btn>
          </template> -->
        </v-infinite-scroll>
      </div>

      <div class="cw-wrap-input">
        <div class="chat-input">
          <v-textarea v-if="activeChat"
            id="cwInputMessage"
            v-model="txtMessage"
            placeholder="ここに質問を入力してください。"
            variant="solo"
            @keydown="handleKeyEvent($event)"
            :disabled="disabledInputMessage"
            rows="1"
            max-rows="5"
            auto-grow
            no-resize
            class="cw-textarea-input-message"
          ></v-textarea>
          <v-btn class="cw-btn-send-message" v-if="activeChat" :loading="sendMsgloading" :disabled="disabledInputMessage" icon="mdi-send-outline" @click="sendMessage"></v-btn>
        </div>
      </div>

      <WarningDialog 
      :active="showWarningDialog" 
      @close="closeDialog()"
      :content="contentWarning" />

      

    </div>
  </ChatLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import 'highlight.js/styles/tokyo-night-dark.css';
import { Marked } from 'marked';
import { markedHighlight } from "marked-highlight";
import hljs from 'highlight.js';
import sanitizeHtml from 'sanitize-html';

import ChatLayout from '@/layouts/ChatLayout.vue';

import { createChat, postChatMessage } from '@/graphql/mutations';

import * as queries from '@/graphql/queries';

import WarningDialog from '@/components/dialog/WarningDialog.vue';

import Chat from '@/mixins/Chat.js';

export default {
  name: 'ChatView',

  components: {
    ChatLayout,
    WarningDialog
  },

  mixins: [Chat],

  data: () => ({
    markedjs: null,
    messages: [],
    txtMessage: '',
    disabledInputMessage: false,
    disabledInputEditedMessage: false,
    msgBeforeEdited: '',
    nextToken: null,
    organizationId: '',
    sendMsgloading: false,
    sendEditedMsgloading: false,
    showWarningDialog: false,
    contentWarning: null,
    numberOfInputLine: 1,
    contentAreaHeight: 0,
    isCopied: []
  }),

  async mounted() {
    // Getting organizationId
    this.organizationId = this.$store.state.auth.userData.organizationId;

    if (this.activeChat && this.activeChat.id !== undefined) {
      this.messages = await this.getByChatId(this.activeChat.id, false);
    }
    this.scrollBottomMessage();

    this.markedjs = new Marked(
      markedHighlight({
        langPrefix: 'hljs language-',
        highlight(code, lang) {
          const language = hljs.getLanguage(lang) ? lang : 'plaintext';
          let codeContent = hljs.highlight(code, { language }).value;
          const copyIcon = `<button type="button" class="v-btn v-btn--icon v-theme--light v-btn--density-compact v-btn--size-default v-btn--variant-plain mr-1"><span class="v-btn__overlay"></span><span class="v-btn__underlay"></span><!----><span class="v-btn__content" data-no-activator=""><i class="mdi mdi-content-copy mdi v-icon notranslate v-theme--light v-icon--size-default" aria-hidden="true"></i></span><!----><!----></button>`
          return `<div class="code-block-header"><span>${language}</span><div>${copyIcon}</div></div><div class="code-block-content">${codeContent}</div>`;
        }
      })
    );
    this.$nextTick(() => {
      this.handleCodeBlockCopy();
    });
  },

  watch: {
    async activeChat(newChat) {
      if(newChat) {
        this.messages = await this.getByChatId(newChat.id, false);
        this.scrollBottomMessage();
        this.txtMessage = '';
        this.sendMsgloading = false;
        this.disabledInputMessage = false;
        document.getElementById("cwInputMessage").focus();
      } else {
        this.messages = [];
      }
    },
    txtMessage: function() {
      setTimeout(()=>{
        this.handleUpdateInputValue();
      }, 10)
    },
    messages: {
      handler: function () {
        setTimeout(()=>{
          this.handleCodeBlockCopy();
        }, 100)
      },
      deep: true,
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    activeChat() {
      return this.$store.state.chat.activeChat
    }
  },

  methods: {
    handleMarkedJS(content) {
      return this.markedjs.parse(content);
    },

    handleSanitizeHTML(content) {
      return sanitizeHtml(content);
    },

    handleResizeEvent() {
      // this.setMessAreaMaxWidth();
    },

    handleUpdateInputValue() {
      //count text rows
      const textArea = document.querySelector('.cw-textarea-input-message textarea')
      const textLineHeight = parseInt(getComputedStyle(textArea).lineHeight.replace('px', ''));
      const textAreaHeight = parseInt(getComputedStyle(textArea).height.replace('px', ''));
      let numberOfLine = Math.floor((textAreaHeight -30) / textLineHeight);
      if (numberOfLine === this.numberOfInputLine) {
        return
      }
      this.numberOfInputLine = numberOfLine;

      // calculate height value of content area and input area
      const inputArea = document.querySelector('.chat-view .cw-wrap-input');
      const contentArea = document.querySelector('.chat-view .cw-wrap-messages');
      const mobileNavComponent = document.querySelector('.v-layout .toggle-nav-main-area');
      const viewPortHeight = window.innerHeight;
      const mobileNavHeight = parseInt(getComputedStyle(mobileNavComponent).height.replace('px', '')) || 0;
      var inputAreaHeight = [];
      var contentAreaHeight = [];
      for (let i = 0; i < 5; i++) {
        inputAreaHeight.push(124 + i * textLineHeight);
        contentAreaHeight.push(viewPortHeight - 64 - mobileNavHeight - 124 - i * textLineHeight);
      }
      inputArea.style.height = inputAreaHeight[numberOfLine - 1] + 'px';
      contentArea.style.height = contentAreaHeight[numberOfLine - 1] + 'px';
      this.scrollBottomMessage();
      textArea.scrollTop = textArea.scrollHeight
    },

    handleKeyEvent(event, isEditMessage = false, msgIndex = '', msgChatId = '') {
      if (event.keyCode !== 13 || event.shiftKey) {
        return
      }
      if (!event.altKey && !event.metaKey) {
        event.preventDefault();
        isEditMessage ?
        this.sendEditedQuestion(event, msgIndex, msgChatId) :
        this.sendMessage();
        return
      }
      isEditMessage ? 
      this.message.content = this.message.content + "\r\n" :
      this.txtMessage = this.txtMessage + "\r\n";
    },

    preventBlurEvent(event) {
      event.preventDefault()
    },

    setMessAreaMaxWidth() {
      const messArea = document.querySelector('.cw-wrap-messages .message-area');
      if (messArea === null) {
        return
      }
      const messTextAreas = document.querySelectorAll('.cw-wrap-messages .message-area .cw-rm-content');
      const messLogoArea = document.querySelector('.cw-wrap-messages .message-area .cw-rm-icon');
      const messPadding = parseInt(getComputedStyle(messArea.querySelector('.message-area-upper')).paddingRight.replace('px', ''));
      const messLogoWidth = parseInt(getComputedStyle(messLogoArea).width.replace('px', ''));
      const messAreaWidth = parseInt(getComputedStyle(messArea).width.replace('px', ''))
      messTextAreas.forEach(e => {
        e.style.maxWidth = messAreaWidth - messLogoWidth - messPadding + 'px'
      })
    },

    async sendMessage() {
      this.disabledInputMessage = true;
      this.sendMsgloading = true;

      const isValid = await this.validateMessage(this.txtMessage);
      if(!isValid) {
        this.disabledInputMessage = false;
        this.sendMsgloading = false;
        return false;
      }

      // Add new if the first message
      let isNew = false;
      if (this.activeChat?.id.includes("new-")) {
        isNew = true;
      }
      
      // Save chat message and create chat (it is new chat)
      this.saveChatMessage(this.txtMessage, isNew, 0);
    },
    
    async hasBlockedKeywords(chatMessage) {
      // Get BlockedKeywords list
      const variables = {
        'organizationId': this.organizationId,
        filter: {
          status: {
            eq: 'Active'
          }
        }
      };
      await this.$store.dispatch('blockedKeyword/callApiGettingBlockedKeywords', variables);
      const blockedKeywords = await this.$store.getters['blockedKeyword/getBlockedKeywords'];
      // Checking the chat message
      let isInvalid = false;
      isInvalid = blockedKeywords.some(blockedKeyword => {
        if (this.isInvalidByRule(blockedKeyword.condition, blockedKeyword.keywords, chatMessage)) {
          return true;
        }
      });
      // return result
      return isInvalid;
    },

    isInvalidByRule(type, keywords, message) {
      // Define
      const jpRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/; //Punctuation, Hiragana, Katakana, Kanji. Readmore: http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
      let invalidTotal = 0;
      const keywordTotal = keywords.length;
      // Execute to check chat message => break if chat message is invalid
      for (let i = 0; i < keywordTotal; i++) {
        let isJapanese = jpRegex.test(keywords[i])
        let pattern = isJapanese ? new RegExp(keywords[i]) : new RegExp("\\b"+ keywords[i] +"\\b");
        if(pattern.test(message)) {
          invalidTotal++;
        }

        if (type == 'Included' && invalidTotal > 0) {
          break;
        }
      }
      // Check by type
      if ((type == 'Included' && invalidTotal > 0) || (type == 'IncludeAll' && invalidTotal == keywordTotal)) {
        return true;
      }
      return false;
    },

    editQuestion(event) {
      let messageContainer = event.target.closest('.message-area')
      this.msgBeforeEdited = messageContainer.querySelector('.v-textarea textarea').value;
      messageContainer.querySelector('.edit-question-area').classList.remove('d-none');
      messageContainer.querySelector('.v-textarea textarea').focus();
      messageContainer.querySelector('.cw-rm-content-q-div').classList.add('d-none');
    },

    blurEditedQuestion(event, message) {
      message.content = this.msgBeforeEdited;
      setTimeout(function() {
        event.target.closest('.edit-question-area').previousElementSibling.classList.remove('d-none');
        event.target.closest('.edit-question-area').classList.add('d-none');
        // this.disabledInputEditedMessage = false;
        // this.sendEditedMsgloading = false;
      }, 500);
    },
    
    async sendEditedQuestion(event, msgIndex, msgChatId) {
      // disable input and enable loading
      this.disabledInputEditedMessage = true;
      this.sendEditedMsgloading = true;
      this.disabledInputMessage = true;
      this.sendMsgloading = true;
      // Edited Message
      const editedMessage = event.target.closest('.message-area-upper').querySelector('textarea').value;
      // Getting deleted indexes
      var deletedIndexes = [];
      this.messages.forEach((message, index) => {
        if (message.index !== '' && message.index >= msgIndex && message.chatId == msgChatId) {
          deletedIndexes.push(index);
        }
        if (message.index === '') {
          this.messages.splice(index, 1);
        }
      });
      // Check validate
      const isValid = await this.validateMessage(editedMessage);
      if (!isValid) {
        this.disabledInputEditedMessage = false;
        this.sendEditedMsgloading = false;
        return false;
      }
      // Remove deleted messages
      for (var i = deletedIndexes.length - 1; i >= 0; i--) {
        this.messages.splice(deletedIndexes[i], 1);
      }
      // Saving edited chat message
      this.saveChatMessage(editedMessage, false, msgIndex);
      // enable input and disable loading
      this.disabledInputEditedMessage = false;
      this.sendEditedMsgloading = false;
    },

    async validateMessage(txtMsg) {
      if(txtMsg.trim() == '') {
        return false;
      }
      try {
        const invalidMessage = await this.hasBlockedKeywords(txtMsg);
        if(invalidMessage) {
          this.contentWarning = `
          除外キーワードに一致する内容が存在したため<br> 
          AIへの問い合わせができませんでした。<br>
          もう一度入力してください。
          `;
          this.showWarningDialog = true;
          return false;
        }
  
        //check string length by aiModel
        let checkedLength = 20000
        if (this.activeChat.aiModel == 'gpt-3.5-32k' || this.activeChat.aiModel == 'gpt-4-32k') {
          checkedLength = 30000
        } else if (this.activeChat.aiModel == 'gpt-3.5' || this.activeChat.aiModel == 'gpt-3.5-turbo') {
          checkedLength = 3000
        } else if (this.activeChat.aiModel == 'gpt-3.5-turbo-16k') {
          checkedLength = 12000
        } else if (this.activeChat.aiModel == 'gpt-4') {
          checkedLength = 6000
        } else if (this.activeChat.aiModel == 'gpt-4-turbo') {
          checkedLength = 100000
        }
  
        if (txtMsg.length > checkedLength) {
            const textWarning = checkedLength + "桁以内で入力してください。" ;
            this.contentWarning = textWarning;
            this.showWarningDialog = true;
            return false;
        }
      } catch (error) {
        console.log("validate fail: ", error.message)
        this.contentWarning = 'チャットメッセージ送信時に<br>エラーが発生しました。';
        this.showWarningDialog = true;
        return false;
      }

      return true;
    },

    async loadMoreMessages ({ done }) {
      if (this.activeChat) {
        if(this.nextToken === null) {
          done('empty'); // There are no more items
        } else {
          this.messages = await this.getByChatId(this.activeChat.id, true);
          done('ok'); // There are more items
        }
      } else {
        done('empty');
      }
    },

    scrollBottomMessage() {
      this.$nextTick(() => {
        document.getElementById("cwWrapMessagesPaging").scrollTop = document.getElementById("cwWrapMessagesPaging").scrollHeight;
      });
    },

    async saveChatMessage(txtMessage, isNewChat, index) {
      // Get JwtToken
      var currentChat = this.activeChat;
      const authSession = await Auth.currentSession();
      const jwtToken = authSession.getIdToken().getJwtToken();
      const lastMessId = this.messages[this.messages.length -1]?.id
      this.messages.push({
        id: 'temporary-id',
        ownerId: currentChat.ownerId,
        organizationId: this.organizationId,
        chatId: '',
        index: '',
        role: 'user',
        sender: currentChat.ownerId,
        temperature: currentChat.temperature,
        content: txtMessage,
        createdAt: new Date(),
        updatedAt: '',
        __typename: ''
      });
      this.scrollBottomMessage();
      try {
        // Creating new chat
        if (isNewChat) {
          // Check max num of chats
          const maxChat = this.$store.state.auth.organization.quota.maxChatEntries;
          const responseChat = await API.graphql({
            query: queries.chatByOwnerId,
            variables: {
              ownerId: this.$store.state.auth.userData.id,
            },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            authToken: jwtToken
          });
          const chats = responseChat.data.chatByOwnerId.items;
  
          if (chats.length >= maxChat) {
            console.log("reached max num of chats");
            this.contentWarning = `
            チャットの上限数を超過しています。<br>
            新しいチャットを追加することができません。
            `;
            this.showWarningDialog = true;
            this.disabledInputMessage = false;
            this.sendMsgloading = false;
  
            return;
          }

          const newChatInput = {
            ownerId: this.activeChat.ownerId,
            ownerName: this.activeChat.ownerName,
            organizationId: this.activeChat.organizationId,
            knowledgeId: this.activeChat.knowledgeId,
            title: this.activeChat.title,
            isShared: this.activeChat.isShared,
            aiType: this.activeChat.aiType,
            aiModel: this.activeChat.aiModel,
            temperature: this.activeChat.temperature,
            hasAiRole: this.activeChat.hasAiRole,
            aiRoleContent: this.activeChat.aiRoleContent !== null ? this.activeChat.aiRoleContent : '',
            numOfMessages: 0,
            postedAt: new Date().toISOString()
          };

          const createdChat = await API.graphql({
            query: createChat,
            variables: { input: newChatInput },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            authToken: jwtToken
          })

          // Update to insertedChat
          currentChat = createdChat.data.createChat;
        }

        // Inserting chat messages
        const chatMessageInput = {
          chatId: currentChat.id,
          content: txtMessage,
          role: 'user',
          temperature: currentChat.temperature
        };

        if (index > 0) {
          chatMessageInput.index = index;
        }

        const result = await API.graphql({
          query: postChatMessage,
          variables: chatMessageInput,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          authToken: jwtToken
        });
        
        const newChatMessages = [
          result.data.postChatMessage.message,
          result.data.postChatMessage.response
        ];

        // Updating store when isNewChat is true
        if (isNewChat) {
          currentChat.title = result.data.postChatMessage.chatTitle;
          this.$store.commit('chat/setActiveChat', currentChat);
          this.$store.commit('chat/setActionChat', 'added');
        }

        // Update Chat 
        // 2023/10/23: Removing because postChatMessage had been executed
        // const updatedChatInput = {
        //   id: this.activeChat.id,
        //   updatedAt: result.data.postChatMessage.response.updatedAt,
        //   postedAt: result.data.postChatMessage.response.updatedAt,
        //   numOfMessages: result.data.postChatMessage.response.index
        // };
        // await API.graphql({
        //   query: updateChat,
        //   variables: { input: updatedChatInput },
        //   authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        //   authToken: jwtToken
        // })

        // Rendering
        if (currentChat.id === this.activeChat.id) {
          this.renderMessage(newChatMessages);
        }

      } catch (error) {
        console.log('Error occurred: ', error);
        const delay = (delayInMillisecond) => {
          return new Promise(resolve => setTimeout(resolve, delayInMillisecond));
        };
        // Check DB
        if ((!isNewChat && currentChat.id === this.activeChat.id) || (isNewChat && this.activeChat?.id.includes("new-"))) {
          let isShowError = true;
          if (error.errors[0].message === 'Execution timed out.') {
            for (let i = 0; i < 20; i++) {
              const mess = await this.getByChatId(currentChat.id, false);
              console.log('Retry times: ', i + 1, mess[mess.length - 1]?.id, lastMessId);
              if (mess.length === 0) {
                await delay(3000);
                continue;
              }
              if ((!isNewChat && mess[mess.length - 1]?.id !== lastMessId) || (isNewChat && mess[mess.length - 1]?.id)) {
                let newChatMessages = mess.slice(-2);
                this.renderMessage(newChatMessages);
                isShowError = false;
                break;
              }
              await delay(3000);
            }
            if (isNewChat && !isShowError) {
              let newChat = await API.graphql({
                query: queries.getChat,
                variables: {
                  id: currentChat.id, 
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
              });
              currentChat.title = newChat.title;
              this.$store.commit('chat/setActiveChat', currentChat);
              this.$store.commit('chat/setActionChat', 'added');
            }
          }
          if (isShowError) {
            this.messages.push({
              id: '',
              ownerId: '',
              organizationId: '',
              chatId: '',
              index: '',
              role: 'error message',
              sender: 'system',
              temperature: '',
              content: error.errors[0].message,
              createdAt: new Date(),
              updatedAt: '',
              __typename: ''
            });
          }
        }
      } finally {
        this.disabledInputMessage = false;
        this.sendMsgloading = false;
        this.txtMessage = '';
        this.scrollBottomMessage();
        setTimeout(() => {
          document.getElementById("cwInputMessage").focus();
        });
      }
    },
    async renderMessage(newChatMessages) {
      const lastMessageIndex = this.messages.length - 1;
      if (this.messages[lastMessageIndex].id === 'temporary-id') {
        this.messages[lastMessageIndex].id = newChatMessages[0].id
        this.messages[lastMessageIndex].chatId = newChatMessages[0].chatId
        this.messages[lastMessageIndex].index = newChatMessages[0].index
        this.messages[lastMessageIndex].updatedAt = newChatMessages[0].updatedAt
        this.messages[lastMessageIndex].__typename = newChatMessages[0].__typename
      }

      newChatMessages.forEach(chatMessage => {
        if (this.messages[this.messages.length - 1].id !== chatMessage.id) {
          this.messages.push({
            id: chatMessage.id,
            ownerId: chatMessage.ownerId,
            organizationId: chatMessage.organizationId,
            chatId: chatMessage.chatId,
            index: chatMessage.index,
            role: chatMessage.role,
            sender: chatMessage.sender,
            temperature: chatMessage.temperature,
            content: chatMessage.content,
            createdAt: chatMessage.createdAt,
            updatedAt: chatMessage.updatedAt,
            __typename: chatMessage.__typename
          });
        }
      })
    },
    async getByChatId(chatId, isLoadMore) {
      // Get JwtToken
      const authSession = await Auth.currentSession();
      const jwtToken = authSession.getIdToken().getJwtToken();
      // Get current chat messages
      let currentMessages = this.messages;
      // Setting empty data if isLoadMore is false 
      if (isLoadMore === false) {
        this.nextToken = null;
        currentMessages = [];
      }
      // Get backend data
      try {
        var result = await API.graphql({
          query: queries.messageByChatId,
          variables: {
            chatId: chatId, 
            sortDirection: "DESC",
            limit: 20,
            nextToken: this.nextToken
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          authToken: jwtToken
        });
        this.nextToken = result.data.messageByChatId.nextToken;
  
        // Reverse response chat messages data
        let responseMessages = [];
        if (result.data.messageByChatId.items.length > 0) {
          responseMessages = result.data.messageByChatId.items.reverse();
        }
        // merge current message and response messages and set this.messages
        // this.messages = responseMessages.concat(currentMessages);
        return responseMessages.concat(currentMessages);
        
      } catch (e) {
        console.log('get chat mess failed: ', e);
        this.showWarningDialog = true;
        this.contentWarning = 'メッセージデータのアクセスに失敗しました。';
      }
    },
    
    closeDialog() {
      this.showWarningDialog = false;
      this.sendMsgloading = false;
      this.contentWarning = null; 
      this.disabledInputMessage = false;
    },
    copyMessage(message) {
      this.isCopied = []
      this.isCopied[message.id] = true;
      navigator.clipboard.writeText(message.content)
      setTimeout(() => {
        this.isCopied[message.id] = false;
      }, 3000);
    },
    handleCodeBlockCopy() {
      const elements = document.querySelectorAll('.code-block-header button')
      elements.forEach(element => {
        if (element.getAttribute('data-isHasClickEvent') !== 'true') {
          element.addEventListener("click", function(){
            element.setAttribute('data-isHasClickEvent', 'true')
            const codeContent = element.closest('code').querySelector('.code-block-content')
            const copyIconClass = element.querySelector('i').classList
            navigator.clipboard.writeText(codeContent.textContent)
            copyIconClass.remove('mdi-content-copy')
            copyIconClass.add('mdi-check')
            setTimeout(() => {
              copyIconClass.remove('mdi-check')
              copyIconClass.add('mdi-content-copy')
            }, 3000);
          });
        }
      })
    }
  }
}
</script>
