<template>
    <DefaultLayout>
      <ChatHistoryPage />
    </DefaultLayout>
  </template>
  
  <script>
  // Components
  import DefaultLayout from '@/layouts/DefaultLayout.vue';
  import ChatHistoryPage from '@/components/chat-histories/IndexPage.vue';
  export default {
    name: 'ChatHistoryView',
    components: {
      DefaultLayout,
      ChatHistoryPage
    },
    data () {
        return {
          drawer: true,
          rail: true,
        }
      },
  }
  </script>
  
  