<template>
    <v-dialog v-model="showDialog" @click:outside="closeDialog(isReloadedUserData)" class="dialog-width">
        <v-card>
            <v-toolbar color="primary" dark>
                <v-toolbar-title class="ml-title-card">{{ user === null ? 'ユーザー追加' : 'ユーザー編集'}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container class="d-none-input-form">
                    <v-row>
                        <!-- update company ID -->
                        <v-col cols="4 lh-field v-col-sm-2 v-col-lg-2">/{{ currentUser.organizationId }}/</v-col>
                        <v-col cols="8 v-col-sm-10 v-col-lg-10">
                            <v-text-field
                                label="ID"
                                name="id"
                                variant="outlined"
                                v-model="uid"
                                density="compact"
                                :disabled="user === null ? false : true">
                            </v-text-field>
                            <p class="text-error">{{ uidError.message }}</p>
                            <p v-for="error of v$.uid.$errors" :key="error.$uid">
                                <span class="text-error">{{ error.$message }}</span>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="名前"
                                name="name"
                                variant="outlined"
                                density="compact"
                                v-model="name">
                            </v-text-field>
                            <p v-for="error of v$.name.$errors" :key="error.$uid">
                                <span class="text-error">{{ error.$message }}</span>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="メールアドレス"
                                name="email"
                                variant="outlined"
                                density="compact"
                                v-model="email" :disabled="user === null ? false : true">
                            </v-text-field>
                            <p class="text-error">{{ emailError.message }}</p>
                            <p v-for="error of v$.email.$errors" :key="error.$uid">
                                <span class="text-error">{{ error.$message }}</span>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                label="ロール"
                                item-title="state"
                                item-value="value"
                                variant="outlined"
                                density="compact"
                                v-model="role" :items="selectRoles" :disabled="isCurrentUser()">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                label="状態"
                                item-title="state"
                                item-value="value"
                                variant="outlined"
                                density="compact"
                                :items="selectStatus" v-model="status" :disabled="isCurrentUser()">
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center my-3">
                <v-btn class="btn-dialog" color="primary" variant="elevated" @click="createUser" :loading="loading">{{ user === null ? '保存' : '更新'}}</v-btn>
                <v-btn class="btn-dialog" variant="elevated" @click="closeDialog(isReloadedUserData)" :disabled="isDisabled()">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <WarningDialog
        :active="showWarningDialog"
        @close="this.showWarningDialog = false"
        :content="contentWarning" 
    />
</template>

<script>
import { API, Auth } from "aws-amplify"
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import * as mutations from '@/graphql/mutations'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, helpers, email } from "@vuelidate/validators"
import * as queries from '@/graphql/queries'
import WarningDialog from '@/components/dialog/WarningDialog.vue'
export default {
    components: {
        WarningDialog
    },
    emits: ['close', 'mutateUserFail'],
    props:
    {
        active: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: null
        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    computed: {
        showDialog() {
            return this.active;
        },

        currentUser() {
            return this.$store.state.auth.userData;
        }
    },
    data() {
        return {
            uid: "",
            name: "",
            email: "",
            role: "",
            status: "",
            description: "",
            organizationId: "",
            owner: false,
            knowledges: [],
            users: [],
            submitted: false,
            keywords: [],
            selectSearch: "id",
            loading: false,
            showWarningDialog: false,
            selectRoles: [
                { state: "管理者", value: "Administrator" },
                { state: "一般ユーザー", value: "User" },
            ],
            selectStatus: [
                { state: "有効", value: "Active" },
                { state: "無効", value: "Inactive" },
            ],
            uidError: {},
            emailError: {},
            isReloadedUserData: false,
            contentWarning: `
                ユーザーの上限数を超過しています。<br>
                新しいユーザーを追加することができません。
            `
        }
    },
    validations() {
        return {
            uid: {
                required: helpers.withMessage('必ず入力してください。', required),
                maxLength: helpers.withMessage(
                    () => `20桁以内で入力してください。`, maxLength(20)
                ),
                regex: helpers.withMessage(
                    () => `IDは英数字、ピリオド(.)、ハイフン(-)、アンダーバー(_)のみ入力可能です。`, helpers.regex(/^[a-z0-9._-]*$/)
                )
            },
            name: {
                required: helpers.withMessage('必ず入力してください。', required),
                maxLength: helpers.withMessage(
                    () => `20桁以内で入力してください。`, maxLength(20)
                )
            },
            email: {
                required: helpers.withMessage('必ず入力してください。', required),
                maxLength: helpers.withMessage(
                    () => `50桁以内で入力してください。`, maxLength(50)
                ),
                email: helpers.withMessage(
                    () => `メールアドレスの形式が正しくありません。`, email
                )
            }
        }
    },
    watch: {
        $props: {
            handler() {
                console.log('Something changed');
                if (this.user !== null) {
                    this.id = "/" + this.user.organizationId + "/" + this.user.uid;
                    this.organizationId = this.user.organizationId;
                    this.uid = this.user.uid;
                    this.name = this.user.name;
                    this.email = this.user.email;
                    this.description = this.user.description;
                    this.status = this.user.status;
                    this.role = this.user.role;
                    this.owner = this.user.owner;
                    this.knowledges = this.user.knowledges;
                } else {
                    this.uid = '';
                    this.name = '';
                    this.email = '';
                    this.organizationId = '';
                    this.description = '';
                    this.status = 'Active';
                    this.role = 'User';
                    this.owner = false;
                    this.knowledges = [];
                }

            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        async createUser() {
            this.submitted = true;
            this.loading = true;
            this.uidError = {};
            this.emailError = {};
            const authSession = await Auth.currentSession();
            const jwtToken = authSession.getIdToken().getJwtToken();
            const user = {
                id: "/" + this.currentUser.organizationId + "/" + this.uid,
                organizationId: this.currentUser.organizationId,
                uid: this.uid,
                name: this.name,
                email: this.email,
                description: this.description,
                status: this.status,
                role: this.role,
                owner: false,
                knowledges: [],
            };

            //Validate
            const result = await this.v$.$validate();
            if (!result) {
                console.log('Invalid input');
                this.submitted = false;
                this.loading = false;

                return;
            }

            // UPDATE USER
            if (this.user) {
                let response = await API.graphql({
                    query: mutations.updateUserFunction,
                    variables: { input: user },
                    authToken: jwtToken
                })

                console.log('update user status: ', response.data.updateUserFunction)
                this.submitted = false;
                if (response.data.updateUserFunction) {
                    this.isReloadedUserData = true;
                    this.closeDialog(this.isReloadedUserData);

                    //update store
                    if (authSession.getIdToken().payload['cognito:username'] === user.id) {
                        let updatedCurrentUser = this.currentUser;
                        updatedCurrentUser.name = user.name;
                        this.$store.commit('auth/setUserData', updatedCurrentUser);
                    }
                } else {
                    this.$emit("mutateUserFail", 'update');
                }

                return;
            }

            //CREATE USER
            const responseUser = await API.graphql({
                query: queries.userByOrganizationId,
                variables: {
                    organizationId: this.currentUser.organizationId
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });
            let maxUsers = this.$store.state.auth.organization.quota.maxUsers
            
            if (responseUser.data.userByOrganizationId.items.length >= maxUsers) {
                this.showWarningDialog = true;
                this.loading = false;

                return;
            }

            const responseUserByUid = await API.graphql({
            query: queries.userByOrganizationIdAndUid,
                variables: {
                    organizationId: this.currentUser.organizationId,
                    uid: {
                        eq: this.uid
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });

            if (responseUserByUid.data.userByOrganizationIdAndUid.items.length > 0) {
                this.submitted = false;
                this.loading = false;

                return this.uidError = {
                    "error": "uid",
                    "message": "指定されたIDは既に存在します。"
                }
            }

            const responseUserByEmail = await API.graphql({
            query: queries.userOrganizationIdAndEmail,
                variables: {
                    organizationId: this.currentUser.organizationId,
                    email: {
                        eq: this.email
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });

            if (responseUserByEmail.data.userOrganizationIdAndEmail.items.length > 0) {
                this.submitted = false;
                this.loading = false;

                return this.emailError = {
                    "error": "email",
                    "message": "指定されたメールアドレスは既に存在します。"
                }
            }

            let response = await API.graphql({ 
                query: mutations.createUserFunction, 
                variables: { input: user },
                authToken: jwtToken
            });
            
            console.log('create user status: ', response.data.createUserFunction)
            this.submitted = false;
            if(response.data.createUserFunction.success) {
                this.isReloadedUserData = true;
                this.closeDialog(this.isReloadedUserData);
                return;
            }
            this.$emit("mutateUserFail", 'create');
        },

        isCurrentUser() {
            if (this.user != null && this.user.id == this.currentUser.id) {
                return true;
            }

            return false;
        },

        closeDialog(isReloadedUserData) {
            this.v$.$reset();
            this.$emit("close", isReloadedUserData);
            this.isReloadedUserData = false;
            this.uidError = {};
            this.emailError = {};
            this.loading = false;
        },

        isDisabled() {
            if (this.loading) {
                return true;
            }

            return false;
        }
    }
}
</script>