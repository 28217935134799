<template>
    <div class="d-flex justify-center mt-3">
        <v-btn class="previous-button" @click="handlePreviousBtn()" :disabled="previousBtnDisable">
            <v-icon icon="mdi-chevron-left" size="large"></v-icon>
            前へ
        </v-btn>
        <v-btn class="next-button" @click="handleNextBtn()" :disabled="nextBtnDisable">
            次へ
            <v-icon icon="mdi-chevron-right" size="large"></v-icon>
        </v-btn>
    </div>
</template>
  
<script>
export default {
    props: {
        pageStatus: {
            currentPage: {
                type: Number,
                default: 0
            },
            maxPage: {
                type: Number,
                default: null
            },
            isPaginationFinish: {
                type: Boolean,
                default: false
            },
        }
    },
    computed: {
        previousBtnDisable() {
            return this.pageStatus.currentPage > 1 ? false : true
        },
        nextBtnDisable() {
            return this.pageStatus.currentPage === this.pageStatus.maxPage && this.pageStatus.isPaginationFinish === true ? true : false
        }
    },
    methods: {
        handleNextBtn() {
            this.$emit('changePage', 'next');
        },
        handlePreviousBtn() {
            this.$emit('changePage', 'prev');
        }
    },
};
</script>