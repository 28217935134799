import { API, Auth} from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { chatByOwnerId, chatByOrganizationId, chatByOwnerIdAndIsShared, chatByOrganizationIdAndIsShared, chatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt } from '@/graphql/queries';
import { copyChatFunction } from '@/graphql/mutations';
export const chat = {
    namespaced: true,
    state: {
        chats: [],
        activeChat: {},
        actionChat: ''
    },
    mutations: {
        setActiveChat (state, payload) {
            state.activeChat = payload;
        },
        setChats(state, payload) {
            state.chats = payload;
        },
        removeChat(state, payload) {
            const toDeleteIndex = state.chats.findIndex((obj) => obj.id === payload.id);
            if (toDeleteIndex > -1) {
                state.chats.splice(toDeleteIndex, 1);
            }
        },
        setActionChat (state, payload) {
            state.actionChat = payload;
        }
    },
    actions: {
        async getChats({commit}, variables) {
            let arr = []
            arr['chatByOwnerId'] = chatByOwnerId
            arr['chatByOwnerIdAndIsShared'] = chatByOwnerIdAndIsShared
            arr['chatByOrganizationIdAndIsShared'] = chatByOrganizationIdAndIsShared
            arr['chatByOrganizationId'] = chatByOrganizationId
            arr['chatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt'] = chatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            const resChats = await API.graphql({
                query: arr[variables.query],
                variables: variables.param,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });
            commit("setChats", resChats.data[variables.query].items)
            return {
                'items' : resChats.data[variables.query].items,
                 'nextToken' : resChats.data[variables.query].nextToken
            }
        },
        async copyChat({commit}, variables) {
            const authSession = await Auth.currentSession();
            const jwtToken = authSession.getIdToken().getJwtToken();
            const res = await API.graphql({
                query: copyChatFunction,
                variables: {input: variables},
                authToken: jwtToken
            });
            commit("setChats", res.data.copyChatFunction.item)
            return res.data.copyChatFunction
        },
    },
    getters: {
        getChats: (state) => state.chats
    }
}