<template>
    <v-container>
        <!-- Organization Block -->
        <OrganizationBlock />

        <v-container>
            <v-card-title primary-title class="bd-title">
                <div class="display-title">
                    <div class="w-title">ユーザー一覧 </div>
                    <v-btn class="btn-add" @click="createUser">ユーザー追加</v-btn>
                    <UserDialog
                        ref="userDialogRef"
                        :active="showUserDialog"
                        :user="editingUser"
                        @mutateUserFail="showErrorPopup"
                        @close="onCloseUserDialog($event)"/>
                </div>
            </v-card-title>
            <div class="pd-content">
                <div class="v-row">
                    <div class="v-col-lg-2 v-col-4">
                        <v-select
                            v-model="selectSearch"
                            :items="selectItems"
                            item-title="state"
                            item-value="value"
                            variant="outlined"
                            density="compact">
                        </v-select>
                    </div>
                    <div class="v-col-lg-3 v-col-6">
                        <v-text-field variant="outlined" density="compact" v-model="filter" @keyup="getUserByCondition(infoUsers)"></v-text-field>
                    </div>
                </div>
                <v-data-table
                    v-model:page="pageStatus.currentPage"
                    v-model:sort-by="sortBy"
                    :headers="headerUsers"
                    :items="getUsersByFilter(infoUsers)" 
                    :items-per-page="itemsPerPage"
                    :no-data-text="noResultsText"
                    :loading="isLoading"
                    @update:sort-by="sortTable"
                    hide-default-footer
                    hover
                    class="usage-management-table elevation-1">
                    <template #loading>
                        <p>{{loadingText}}</p>
                    </template>

                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.columns.uid }}</td>
                            <td class="management-name-column">{{ item.columns.name }}</td>
                            <td class="management-email-column">{{ item.columns.email }}</td>
                            <td>{{ item.columns.role == "User" ? '一般ユーザー' : '管理者' }}</td>
                            <td class="td-user">
                                <v-switch 
                                    v-if="!isCurrentUser(item.columns.id)"
                                    class="icon-active"
                                    inset v-model="item.columns.status"
                                    true-value="Active" false-value="Inactive"
                                    @click="editUserActive(item.columns)" color="primary">
                                </v-switch>
                            </td>
                            <td class="text-center">
                                <v-btn variant="text" class="btn-icon" @click="editUser(item.columns)"><v-icon icon="mdi-pencil-outline"></v-icon></v-btn>
                                <v-btn class="btn-icon" :disabled="isCurrentUser(item.columns.id)" variant="text" @click="handleClickOnDeleteIcon(item.columns.id)"><v-icon icon="mdi-delete-outline"></v-icon></v-btn>
                            </td>
                        </tr>
                    </template>

                    <template v-slot:bottom>
                        <div class="pb-paginiton-table">
                            <PaginationComponent
                            @changePage="changPage"
                            :pageStatus="pageStatus"
                            ></PaginationComponent>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-container>

        <!-- Blocked Keyword Block -->
        <BlockedKeyword />

        <ConfirmDeleteDialog :active="showDeleteUserDialog" @close="onCloseDeleteUserDialog" @delete="deleteUserById" :is-loading="loadingConfirmDelete">
            <template v-slot:dialog-title>
                ユーザー削除の確認
            </template>
            <template v-slot:dialog-content>
                このユーザーを削除しますか？
            </template>
        </ConfirmDeleteDialog>
        <WarningDialog 
            :active="showWarningDialog" 
            @close="closeDialog()"
            :content="contentWarning" />
    </v-container>
</template>

<script>
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import UserDialog from '@/components/dialog/UserDialog.vue';
import WarningDialog from '@/components/dialog/WarningDialog.vue';
// Queries & Mutations
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
// Block
import OrganizationBlock from '@/components/management/OrganizationBlock.vue';
import BlockedKeyword from '@/components/management/BlockedKeyword.vue';

import ConfirmDeleteDialog from '@/components/dialog/ConfirmDeleteDialog.vue';
import PaginationComponent from '@/components/defaults/PaginationComponent.vue';

export default {  
    components: {
        OrganizationBlock,
        UserDialog,
        WarningDialog,
        BlockedKeyword,
        ConfirmDeleteDialog,
        PaginationComponent
    },
    data () {
        return {
        showUserDialog: false,
        showDeleteUserDialog: false,
        editingUser: {},
        deleteUserId: "",
        page: 1,
        itemsPerPage: 5,
        selectSearch: 'id',
        filter: '',
        userByCondition: [],
        selectItems: [
            { state: "ID", value: "id" },
            { state: "名前", value: "name" },
            { state: "メールアドレス", value: "email" },
        ],
        headerUsers: [
          { title: 'ID', align: 'start', key: 'id' },
          { title: '名前', align: 'start', key: 'name' },
          { title: 'メールアドレス', align: 'start', key: 'email' },
          { title: 'ロール', align: 'start', key: 'role', sortable: false, width: '150' },
          { title: '無効 ・有効', align: 'center', key: 'status', sortable: false, width: '125' },
          { title: '操作', align: 'center', key: 'uid', sortable: false, width: '125' },
        ],
        noResultsText: "表示するデータがありません。",
        loadingText: "読み込み中",
        pageStatus: {
            currentPage: 1,
            maxPage: null,
            isPaginationFinish: false
        },
        inputTimeout: '',
        sortBy: [{ key: 'createdAt', order: 'desc' }],
        sortKey: 'createdAt',
        sortDir: 'DESC',
        infoUsers: [],
        isLoading: false,
        loadingConfirmDelete: false,
        nextToken: null,
        showWarningDialog: false,
        contentWarning: ''
      }
    },
    computed: {
      pageUserCount () {
        return Math.ceil(this.users.length / this.itemsPerPage)
      },

      currentUser() {
        return this.$store.state.auth.userData;
      }
    },
    methods: {
        async getUsers() {
            //check auth
            const isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                this.$router.push("/login");
            }
            this.isLoading = true;
            let queryLink = "";
            let fieldType = "";
            try {
                do {
                    if (this.sortKey === 'createdAt') {
                        queryLink = queries.userByOrganizationId;
                        fieldType = "userByOrganizationId";
                    }
                    if (this.sortKey === 'id') {
                        queryLink = queries.userByOrganizationIdAndUid;
                        fieldType = "userByOrganizationIdAndUid";
                    }
                    if (this.sortKey === 'name') {
                        queryLink = queries.userOrganizationIdAndName;
                        fieldType = "userOrganizationIdAndName";
                    }
                    if (this.sortKey === 'email') {
                        queryLink = queries.userOrganizationIdAndEmail;
                        fieldType = "userOrganizationIdAndEmail";
                    }
    
                    const authSession = await Auth.currentSession()
                    const jwtToken = authSession.getIdToken().getJwtToken()
                    const response = await API.graphql({
                        query: queryLink,
                        variables: {
                            organizationId: this.currentUser.organizationId,
                            sortDirection: this.sortDir,
                            nextToken: this.nextToken
                        },
                        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                        authToken: jwtToken
                    });
    
                    response.data[fieldType].items.forEach(e => {this.infoUsers.push(e)});
                    this.nextToken = response.data[fieldType].nextToken;
                } while (this.nextToken !== null)
                this.isLoading = false;
            } catch (e) {
                console.log('get user failed: ', e);
                this.showErrorPopup('getUserFail');
            }
        },

        //update button Active and Inactive
        async editUserActive(user) {
            user.status = user.status === "Inactive" ? "Active" : "Inactive";
            //check auth
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                return this.$router.push("/login");
            }
            const userInput = {
                id: user.id,
                organizationId: this.currentUser.organizationId,
                uid: user.uid,
                name: user.name,
                email: user.email,
                description: "",
                status: user.status,
                role: user.role,
                owner: false,
                knowledges: [],
            };

            const authSession = await Auth.currentSession();
            const jwtToken = authSession.getIdToken().getJwtToken();
            // Update
            try {
                await API.graphql({
                    query: mutations.updateUserFunction,
                    variables: { input: userInput },
                    authToken: jwtToken
                })
            } catch (e) {
                console.log('active user failed: ', e);
                this.showErrorPopup('update');
            }
        },

        //create and update
        async createUser() {
            //check auth
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                return this.$router.push("/login");
            }
            console.log("create user");
            this.editingUser = null;
            this.showUserDialog = true;
            console.log(this.editingUser);
        },
        async editUser(user) {
            //check auth
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                return this.$router.push("/login");
            }
            console.log('click on user tab');
            this.editingUser = user;
            this.showUserDialog = true;
            console.log(this.editingUser);
        },
        onCloseUserDialog(event) {
            console.log("onCloseUserDialog");
            this.showUserDialog = false;
            if (event) {
                this.renewTableData();
            }
        },
        showErrorPopup(type) {
            this.showWarningDialog = true;
            if (type === 'create') {
                this.contentWarning = 'ユーザ作成に失敗しました。';
                return;
            }
            if (type === 'update') {
                this.contentWarning = 'ユーザ更新に失敗しました。';
                return;
            }
            if (type === 'delete') {
                this.contentWarning = 'ユーザ削除に失敗しました。';
                return;
            }
            if (type === 'getUserFail') {
                this.contentWarning = 'ユーザー一覧のアクセスに失敗しました。';
                return;
            }
        },
        closeDialog() {
            this.showWarningDialog = false;
            this.contentWarning = '';
            this.isLoading = false;
            if (this.showDeleteUserDialog) {
                this.showDeleteUserDialog = false;
                return;
            }
            this.$refs.userDialogRef.closeDialog(false);
        },
        //Delete user
        async handleClickOnDeleteIcon(userId) {
            //check auth
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                return this.$router.push("/login");
            }
            this.deleteUserId = userId;
            this.loadingConfirmDelete = false;
            this.showDeleteUserDialog = true;
        },
        async deleteUserById() {
            console.log("delete user");
            this.loadingConfirmDelete = true;
            const authSession = await Auth.currentSession();
            const jwtToken = authSession.getIdToken().getJwtToken();

            const result = await API.graphql({ 
                query: mutations.deleteUserFunction, 
                variables: { id: this.deleteUserId },
                authToken: jwtToken
            });

            if(result.data.deleteUserFunction) {
                const toDeleteIndex = this.infoUsers.findIndex((obj) => obj.id == this.deleteUserId);
                if (toDeleteIndex > -1) {
                    this.infoUsers.splice(toDeleteIndex, 1);
                }
                this.showDeleteUserDialog = false;
                return;
            }
            this.showErrorPopup('delete');

            return
        },
        onCloseDeleteUserDialog() {
            console.log("onCloseDeleteUserDialog");
            this.showDeleteUserDialog = false;
        },

        changPage(dir) {
            if (dir === 'prev') {
                this.pageStatus.currentPage--;
                return;
            }
            if (this.pageStatus.currentPage > (this.pageStatus.maxPage - 2) && this.pageStatus.isPaginationFinish === false) {
                this.getUsers()
            }
            this.pageStatus.currentPage++;
        },
        async renewTableData() {
            this.infoUsers = [];
            this.nextToken = null;
            this.pageStatus.currentPage = 1;
            this.pageStatus.maxPage = null;
            this.pageStatus.isPaginationFinish = false;
            await this.getUsers();
        },
        sortTable() {
            if (this.sortBy.length === 0) this.sortBy = [{ key: 'createdAt', order: 'desc' }];
            if (this.sortBy[0].order === 'asc') this.sortDir = 'ASC';
            if (this.sortBy[0].order === 'desc') this.sortDir = 'DESC';
            this.sortKey = this.sortBy[0].key;
            this.renewTableData();
        },
        searchStringInArray (str, objArray) {
            let users  = [];
            for (var i = 0; i < objArray.length; i++) {
                if (
                    (this.selectSearch === 'id' && objArray[i].uid.indexOf(str) > -1) ||
                    (this.selectSearch === 'name' && objArray[i].name.indexOf(str) > -1) ||
                    (this.selectSearch === 'email' && objArray[i].email.indexOf(str) > -1)
                ) users.push(objArray[i]);
            }

            return users;
        },
        getUserByCondition(userArr) {
            let users = [];

            users = this.searchStringInArray(this.filter, userArr);
            this.handlePagination(users);

            return users;
        },
        isCurrentUser(userId) {
            if (this.currentUser && userId === this.currentUser.id) {
                return true;
            }

            return false;
        },
        getUsersByFilter(users) {
            if (this.filter !== '') {
                return this.getUserByCondition(users);
            }

            this.handlePagination(users);

            return users;
        },
        //Handle pagination
        handlePagination(users) {
            if (users.length !== 0) {
                const numPages = Math.floor(users.length / this.itemsPerPage)
                this.pageStatus.maxPage = users.length % this.itemsPerPage ? numPages + 1 : numPages
            }
            if (this.nextToken === null) {
                this.pageStatus.isPaginationFinish = true;
                if (users.length === 0) this.pageStatus.maxPage = 1;
            }
        }
    },

    mounted() {
        this.getUsers();
    }
}
</script>