<template>
    <div class="d-flex align-center justify-center mh">
        <v-sheet class="mx-auto w-login">
            <v-card class="mx-auto px-6 py-login">
                <v-form class="d-none-input-form" ref="form" variant="outlined" @submit.prevent="forgotPassword">
                    <v-card-title primary-title class="title-login">パスワードをお忘れですか？</v-card-title>
                    <v-text-field v-model="username" type="text" label="メールアドレス" variant="outlined"></v-text-field>
                    <p v-if="error" class="text-error">{{ error.message }}</p>
                    <p v-for="error of v$.username.$errors" :key="error.$username">
                        <span class="text-error">{{ error.$message }}</span>
                    </p>
                    <div class="mb-form"></div>

                    <div class="text-center">
                        <v-btn color="primary" type="submit" variant="elevated" width="150px" size="large">次へ</v-btn>
                        <v-btn class="btn-cancel" variant="elevated" size="large" to="/login" style="margin-inline-start: 0.5rem">キャンセル</v-btn>
                    </div>
                </v-form>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from "@vuelidate/validators";
export default {
  data: () => ({
    username: "",
    code: "",
    newPassword: "",
    confirmNewPassword: "",
    error: {}
  }),
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
        username: {
            required: helpers.withMessage('必ず入力してください。', required),
            email: helpers.withMessage(
                () => `メールアドレスの形式が正しくありません。`, email
            )
        }
    }
  },
  methods: {
    async forgotPassword() {
        const result = await this.v$.$validate();
        if (!result) {
            console.log('Invalid input');
            return;
        }
        try {
            await Auth.forgotPassword(this.username);
            this.$store.commit('auth/setForgotEmail', this.username);
            this.$router.push("/reset-password");
        } catch(error) {
            return this.error = {
                "isSuccess": false,
                "message": "メールアドレスが存在しません。"
            };
        }
    }
  },
};
</script>