import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { messageByChatId } from '@/graphql/queries';

export const chatMessage = {
    namespaced: true,
    state: {
        messages: [],
    },
    mutations: {
        setMessages (state, payload) {
            state.messages = payload
        }
    },
    actions: {
        async getMessageByChatId({commit}, variables) {
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            const res = await API.graphql({
                query: messageByChatId,
                variables: variables,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            });
            commit("setMessages", res.data.messageByChatId.items)
            return res.data.messageByChatId.items
        }
    },
    getters: {
        getMessages: (state) => state.messages,
    }
}