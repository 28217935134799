<template>
    <LoginLayout>
        <ChangePasswordForm />
    </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue';
import ChangePasswordForm from '@/components/ChangePasswordForm.vue';
export default {
    components: {
        LoginLayout,
        ChangePasswordForm
    },
}
</script>