import { createStore } from 'vuex'
import {auth} from './auth/auth'
import { knowledge } from './knowledge/knowledge'
import { chat } from './chat/chat'
import { user } from './user/user'
import { blockedKeyword } from './blockedKeyword/blockedKeyword'
import { chatMessage } from './chat-message/chatMessage'
import VuexPersistence from "vuex-persist";

export default createStore({
  modules: {
    auth,
    knowledge,
    chat,
    user,
    blockedKeyword,
    chatMessage
  },
  plugins: [new VuexPersistence().plugin]
})
