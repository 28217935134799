<template>
  <v-app-bar>
    <CompanyLogo/>

    <div class="knowledge-tabs-container">
      <div class="knowledge-tabs-left-area">
        <v-btn
          class="knowledge-draggable-topbar-prev"
          variant="plain"
          density="compact"
          icon="mdi-chevron-left"
          @click="swipeLeftKnowledges"
          v-if="knowledges.length > numberOfKnowledgeTab">
        </v-btn>
        <div ref="knowledgeDraggableTopbar" class="knowledge-draggable-topbar">
          <draggable tag="div" class="content-knowledge-draggable" :list="knowledges" @change="handleDragKnowledge" draggable=".exclude-this-item">
            <v-col v-for="(knowledge, index) in knowledges" :key="knowledge.id" :cols="knowledgeGridValue" :class="index ? 'exclude-this-item' : ''">
              <transition-group>
                <v-btn :key="knowledge.id" height="40" @click="clickOnKnowledgeTab(knowledge, false)" @mouseover="handleKnowledgeHover" class="item-content-knowledge" :class="index == activeKnowledgeIndex ? 'ick-active' : ''">
                  <span class="mx-1 d-inline-block knowledge-name">{{ knowledge.name }}</span>
                  <div class="icon-wrap" v-if="index == activeKnowledgeIndex">
                    <v-icon icon="mdi-pencil-outline" @click="editKnowledge(knowledge)"></v-icon>
                    <v-icon v-if="knowledge.isDefault.toLowerCase() === 'false'" icon="mdi-delete-outline" @click="handleClickOnDeleteIcon(knowledge)"></v-icon>
                  </div>
                  <v-tooltip activator="parent" location="bottom" :disabled="!isShowKnowledgeTooltip">{{ knowledge.name }}</v-tooltip>
                </v-btn>
              </transition-group>
            </v-col>
          </draggable>
        </div>
      </div>
      <div class="knowledge-tabs-right-area">
        <v-btn
          class="ml-2
          knowledge-draggable-topbar-next"
          variant="plain"
          density="compact"
          icon="mdi-chevron-right"
          @click="swipeRightKnowledges"
          v-if="knowledges.length > numberOfKnowledgeTab">
        </v-btn>    
        <v-menu v-if="knowledges.length > numberOfKnowledgeTab">
          <template v-slot:activator="{ props }">
            <v-btn class="ml-1 btn-three-dots" variant="plain" density="compact" icon="mdi-dots-horizontal" v-bind="props"></v-btn>
          </template>
    
          <v-list class="pa-2 list-knowledge-combobox">
            <v-list-item v-for="(knowledge, index) in knowledges" :key="knowledge.id"
            @click="clickOnKnowledgeTab(knowledge, true)"
            class="rounded-lg"
            :class="index == activeKnowledgeIndex ? 'ick-active' : ''">
              {{ knowledge.name }}
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn class="ml-1 btn-plus" variant="plain" density="compact" icon="mdi-plus"  @click="createKnowledge"></v-btn>
      </div>
      <div class="chat-name-mobile" @mouseover="handleChatHover">
        <div>{{ activeChat?.title }}</div>
        <v-tooltip activator="parent" location="bottom" :disabled="!isShowChatTooltip">{{ activeChat?.title }}</v-tooltip>
      </div>
    </div>

    <KnowledgeDialog
      ref="knowledgeDialogRef"
      :active="showKnowledgeDialog"
      @jumpToKnowledges="jumpToKnowledges"
      :knowledge="editingKnowledge"
      @mutateKnowledgeFail="showErrorPopup"
      @createChatAfterKnowledge="createChatAfterKnowledge"
      @close="onCloseKnowledgeDialog"/>
    <ConfirmDeleteDialog
      :active="showConfirmDeleteDialog"
      @close="onCloseConfirmDeleteDialog"
      @delete="onDelete"
      :is-loading="isLoading">
      <template v-slot:dialog-title>
        ナレッジ削除の確認
      </template>
      <template v-slot:dialog-content>
        このナレッジを削除しますか？<br>
        削除したナレッジは復元できません。
      </template>
    </ConfirmDeleteDialog>
    <WarningDialog
      :active="showWarningDialog"
      @close="closeWarningDialog()"
      :content="contentWarning">
    </WarningDialog>

    <NavDefault/>
  </v-app-bar>

  <div class="top-mobile-main">
    <div class="tmm-wrap-row" :class="displayNavMobile" ref="tmmWrapRowArea">
      <div class="tmm-row">
        <div class="tmm-row-l">
          <v-select
            label="ナレッジ"
            :items="knowledges"
            variant="solo"
            density="compact"
            item-title="name"
            item-value="id"
            v-model="activeKnowledge"
            @update:modelValue="clickOnKnowledgeMobile"
            no-data-text="表示するデータがありません。"
          >
          <template v-slot:prepend-item>
            <v-list-item @click="createKnowledge" class="btn-add-new-mobile">
              <v-icon icon="mdi-plus"></v-icon><span>新規ナレッジ作成</span>
            </v-list-item>
          </template>
          </v-select>
        </div>
        <div class="tmm-row-r">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn variant="plain" density="compact" icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>

            <v-list v-if="activeKnowledge" class="chat-action-list-mobile" density="compact">
              <v-list-item @click="editKnowledge(activeKnowledge)">
                編集 <v-icon icon="mdi-pencil-outline"></v-icon>
              </v-list-item>
              <v-list-item @click="handleClickOnDeleteIcon(activeKnowledge)">
                削除 <v-icon icon="mdi-delete-outline"></v-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { deleteKnowledgeFunction } from '@/graphql/mutations';
import NavDefault from '@/components/defaults/NavDefault.vue';
import CompanyLogo from '@/components/defaults/CompanyLogo.vue';
import KnowledgeDialog from '@/components/dialog/KnowledgeDialog.vue';
import ConfirmDeleteDialog from '@/components/dialog/ConfirmDeleteDialog.vue';
import WarningDialog from '@/components/dialog/WarningDialog.vue';
import * as queries from '@/graphql/queries'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  components: {
    NavDefault,
    CompanyLogo,
    KnowledgeDialog,
    ConfirmDeleteDialog,
    WarningDialog,
    draggable: VueDraggableNext,
  },
  setup() {
  },

  props: {
    displayNavMobile: String
  },

  data: () => ({
    showKnowledgeDialog: false,
    showConfirmDeleteDialog: false,
    showWarningDialog: false,
    contentWarning: '',
    editingKnowledge: {},
    knowledges: [],
    activeKnowledgeIndex: 0,
    activeKnowledge: null,
    isLoading: false,
    isShowKnowledgeTooltip: false,
    isShowChatTooltip: false,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentUserData() {
      return this.$store.state.auth.userData;
    },
    storedActiveKnowledge () {
      return this.$store.getters['knowledge/getActiveKnowledge'];
    },
    activeChat() {
      return this.$store.state.chat.activeChat;
    },
    knowledgeGridValue() {
      if (this.$vuetify.display.lgAndUp) {
        return 2;
      }
      if (this.$vuetify.display.mdAndUp) {
        return 3;
      }

      return 6;
    },
    numberOfKnowledgeTab() {
      return 12 / this.knowledgeGridValue;
    }
  },

  async mounted() {
    try {
      await this.$store.dispatch('knowledge/callApiGettingKnowledges');
      this.knowledges = await this.$store.getters['knowledge/getKnowledges'];
    } catch (e) {
      console.log('get knowledge list failed: ', e); 
      this.showErrorPopup('get');
    }
    // Execute activeKnowledge
    this.activeKnowledge = this.storedActiveKnowledge;
    if (this.activeKnowledge === null) {
      this.activeKnowledge = this.knowledges[0];
      this.$store.commit('knowledge/setActiveKnowledge', this.activeKnowledge);
    }
    // Set activeKnowledgeIndex
    this.activeKnowledgeIndex = this.knowledges.findIndex(object => {
      return object.id === this.activeKnowledge.id;
    });

    this.$nextTick(() => {
      this.jumpToKnowledges(0);
    });
  },

  watch: {
    storedActiveKnowledge(newKnowledge, oldKnowledge) {
      if (newKnowledge != null && oldKnowledge != null && this.knowledges != null) {
        this.activeKnowledgeIndex = this.knowledges.findIndex(object => {
          return object.id === newKnowledge.id;
        });
      }
      if(newKnowledge != null) {
        this.activeKnowledge = newKnowledge;
        // this.$nextTick(() => {
        //   this.jumpToKnowledges(0);
        // });
      }
    }
  },

  methods: {
    createChatAfterKnowledge() {
      this.$emit('createChatAfterKnowledge');
    },
    jumpToKnowledges(oldActiveKnowledgeIndex) {
      if(!oldActiveKnowledgeIndex) {
        oldActiveKnowledgeIndex = 0;
      }
      var distance = 0;
      var knowledgeWidth = this.$refs.knowledgeDraggableTopbar.querySelector('.v-col').getBoundingClientRect().width;

      if (this.activeKnowledgeIndex > oldActiveKnowledgeIndex) {
        distance = (this.activeKnowledgeIndex - oldActiveKnowledgeIndex) * knowledgeWidth;
        this.sideScroll(this.$refs.knowledgeDraggableTopbar, 'right', distance);
      } else {
        
        distance = (oldActiveKnowledgeIndex - this.activeKnowledgeIndex) * knowledgeWidth;
        this.sideScroll(this.$refs.knowledgeDraggableTopbar, 'left', distance);
      }
    },
    swipeLeftKnowledges() {
      var knowledgeWidth = this.$refs.knowledgeDraggableTopbar.querySelector('.v-col').getBoundingClientRect().width;
      this.sideScroll(this.$refs.knowledgeDraggableTopbar, 'left', knowledgeWidth);
    },
    swipeRightKnowledges() {
      var knowledgeWidth = this.$refs.knowledgeDraggableTopbar.querySelector('.v-col').getBoundingClientRect().width;
      this.sideScroll(this.$refs.knowledgeDraggableTopbar, 'right', knowledgeWidth);
    },
    sideScroll(element, direction, distance){
      if (direction == 'left'){
          element.scrollLeft -= distance;
      } else {
          element.scrollLeft += distance;
      }
    },

    async handleDragKnowledge(event) {
      console.log("handle drag knowledge");
      console.log(event);
      console.log(this.activeKnowledge);

      const newActiveKnowledgeIndex = this.knowledges.findIndex(object => {
        return object.id === this.activeKnowledge.id;
      });

      // update user.knowledges (DynamoDB)
      await this.$store.dispatch('knowledge/updateUserKnowledges');
      // update user.userData.knowledges (store)
      await this.$store.dispatch('auth/updateUserDataKnowledges');

      this.activeKnowledgeIndex = newActiveKnowledgeIndex;
      this.activeKnowledge = this.knowledges[newActiveKnowledgeIndex];
      this.$store.commit('knowledge/setActiveKnowledge', this.activeKnowledge);
      
    },
    clickOnKnowledgeMobile(kID) {
      var kl = this.getObjectKnowledgeById(kID);
      if(kl !== null) {
        this.clickOnKnowledgeTab(kl, false);
      }
    },

    getObjectKnowledgeById(kID) {
      for (let i = 0; i < this.knowledges.length; i++) {
        if(this.knowledges[i].id == kID) {
          return this.knowledges[i];
        }
      }

      return null;
    },

    editKnowledge(knowledge) {
      console.log('click on edit icon - knowledge tab');
      this.editingKnowledge = knowledge;
      this.showKnowledgeDialog = true;
      console.log(this.editingKnowledge);
    },
    onCloseKnowledgeDialog() {
      console.log("onCloseKnowledgeDialog");
      this.showKnowledgeDialog = false;
    },
    handleClickOnDeleteIcon(knowledge) {
      console.log(knowledge);
      this.isLoading = false;
      this.showConfirmDeleteDialog = true;
    },
    onCloseConfirmDeleteDialog() {
      this.showConfirmDeleteDialog = false;
    },
    async onDelete() {
      this.isLoading = true;
      const deleteKnowledge = this.activeKnowledge;
      try {
        const authSession = await Auth.currentSession()
        const jwtToken = authSession.getIdToken().getJwtToken()
        const res = await API.graphql({
          query: deleteKnowledgeFunction,
          variables: { id: this.activeKnowledge.id, uid: this.currentUser.username },
          authToken: jwtToken
        });

        if (res.data.deleteKnowledgeFunction === true) {
          // remove deleted-knowledge in store 
          this.$store.commit('knowledge/removeKnowledge', deleteKnowledge);
          // change active knowledge
          if (this.activeKnowledgeIndex !== 0) {
            this.activeKnowledgeIndex = this.activeKnowledgeIndex - 1;
          }
          this.activeKnowledge = this.knowledges[this.activeKnowledgeIndex];
          // Update activeKnowledge in store
          this.$store.commit('knowledge/setActiveKnowledge', this.activeKnowledge);
          // update user.knowledges (DynamoDB)
          await this.$store.dispatch('knowledge/updateUserKnowledges');
          // update user.userData.knowledges (store)
          await this.$store.dispatch('auth/updateUserDataKnowledges');

          this.showConfirmDeleteDialog = false;
          
        } else {
          // TODO
          // show warning dialog
        }

      } catch (error) {
        console.log('Error occurred');
        console.log(error);
        this.showErrorPopup('delete');
      }

    },
    async createKnowledge() {
      console.log("create knowledge");
      // Validating: max num of knowledges
      const authSession = await Auth.currentSession()
      const jwtToken = authSession.getIdToken().getJwtToken()
      const responseOrganization = await API.graphql({
        query: queries.getOrganization,
            variables: { id: this.currentUserData.organizationId },
            authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            authToken: jwtToken
        });

      const maxKnowledges = responseOrganization.data.getOrganization.quota.maxKnowledged
      if (this.knowledges.length >= maxKnowledges) {
        console.log("reached max num of knowledges");
        this.showWarningDialog = true;
        this.contentWarning = 'ナレッジの上限数を超過しています。<br>これ以上ナレッジを追加する事が出来ません。'
        return;
      }

      this.editingKnowledge = null;
      this.showKnowledgeDialog = true;
      console.log(this.editingKnowledge);
    },
    clickOnKnowledgeTab(knowledge, isClickFromList) {
      console.log('click on knowledge');
      var oldActiveKnowledgeIndex = this.activeKnowledgeIndex;
      // set active knowledge
      this.$store.commit('knowledge/setActiveKnowledge', knowledge);
      this.activeKnowledge = knowledge;
      // set active index
      this.activeKnowledgeIndex = this.knowledges.findIndex(object => {
        return object.id === knowledge.id;
      });

      if(isClickFromList) {
        this.jumpToKnowledges(oldActiveKnowledgeIndex);
      }
    },
    handleKnowledgeHover(event) {
      const target = event.target.closest('.item-content-knowledge').querySelector('.v-btn__content span')
      this.isShowKnowledgeTooltip = target.offsetWidth < target.scrollWidth
    },
    handleChatHover(event) {
      const target = event.target
      this.isShowChatTooltip = target.offsetWidth < target.scrollWidth
    },
    showErrorPopup(type) {
      this.showWarningDialog = true;
      if (type === 'create') {
          this.contentWarning = 'ナレッジ作成に失敗しました。';
      }
      if (type === 'update') {
          this.contentWarning = 'ナレッジ更新に失敗しました。';
      }
      if (type === 'delete') {
          this.contentWarning = 'ナレッジ削除に失敗しました。';
      }
      if (type === 'get') {
          this.contentWarning = 'ナレッジデータのアクセスに失敗しました。';
      }
    },
    closeWarningDialog() {
      this.showWarningDialog = false;
      this.contentWarning = '';
      if (this.showConfirmDeleteDialog) {
        this.showConfirmDeleteDialog = false;
        this.isLoading = false;
        return;
      }
      this.$refs.knowledgeDialogRef.closeDialog();
    }
  },
}
</script>
