<template>
  <v-radio-group inline v-model="radioSearch" hide-details="auto" class="chat-history-search">
    <v-radio 
      v-for="(radio, i) in radios"
        :key="i"
        :label="radio.label"
        :value="radio.value"
        @click="radioSearch = radio.value; resetSearch()">
    </v-radio>
  </v-radio-group>
  <v-row>
    <v-col class="v-col-xl-2 v-col-sm-3 v-col-4">
      <v-select
        v-model="search.selectSearch"
        :items="selectItems"
        item-title="name"
        item-value="id"
        variant="solo"
        hide-details="auto"
        class="d-lg-2"
        density="compact"
      ></v-select>
    </v-col>
    <v-col cols="v-col-xl-3 v-col-lg-4 v-col-md-6 v-col-sm-7 v-col-8">
      <v-autocomplete
        v-if="search.selectSearch === 'name'"
        v-model="search.ownerName"
        :items="users"
        variant="outlined"
        hide-details="auto"
        @update:modelValue="getChats()"
        no-data-text="表示するデータがありません。"
        class="select-autocomplete"
        density="compact"
      ></v-autocomplete>
      <VueDatePicker 
        v-if="search.selectSearch == 'date'"
        ref="vueDatePicker"
        v-model="search.date"
        :format-locale="ja" range time-picker-inline
        :enable-time-picker="true"   
        :multi-calendars="true"
        :flow="search.flow"
        :format="formatDate"
        :close-on-auto-apply="false"
        placeholder="開始日 - 終了日" class="chat-history-date-picker"
        :timezone="timezone"
        :start-time="search.startTime">
        <template #action-buttons>
          <v-btn color="primary" variant="elevated" class="btn-dialog" @click="selectDate">決定</v-btn>
        </template>
      </VueDatePicker>
    </v-col>
  </v-row>
</template>
<script>
  import { ja } from 'date-fns/locale'
  import moment from 'moment'
  export default {
    props: {
        modelSearch: {
            type: Object
        },
        modelUsers: {
          type: Array
        },
        modelDataTable: {
          type: Object
        },
        user: {
          type: Object
        }
    },
    computed: {
      users() {
        return this.modelUsers
      },
      selectItems() {
        if (this.modelSearch.radioSearch == 'user') {
          return [
            { id: "date" , name: "作成日時" },
          ]
        } else {
          return [
            { id: "name", name: "作成者"},
            { id: "date", name: "作成日時" }
          ]
        }
      }
    },
    data() {
      return {
        search: this.modelSearch,
        dataTable: this.modelDataTable,
        radioSearch: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
        radios: [
          {'value' : 'organization', 'label': '組織内のチャット'},
          {'value' : 'user', 'label': '自分のチャット'}
        ],
        ja: ja,
      }
    },
    watch: {
            $props: {
                handler() {
                  this.radioSearch = this.modelSearch.radioSearch
                  this.resetSearch()
                },
                deep: true,
                immediate: true,
            },
        },
    emits: ["get-chats", "update:modelDataTable"],
    methods: {
        formatDate: function(date) {
          return moment(date[0]).format('YYYY/MM/DD, HH:mm') +' - '+moment(date[1]).format('YYYY/MM/DD, HH:mm')
        },
        getChats: function () {
          this.dataTable.pageStatus = {
            currentPage: 1,
            maxPage: null,
            isPaginationFinish: false
          }
          this.dataTable.nextToken = null
          this.dataTable.chats = []
          this.$emit('update:modelDataTable', this.dataTable)
          this.$emit('get-chats')
        },
        resetSearch: function() {
          if (this.search.radioSearch === 'user') {
            this.search.selectSearch = 'date'
          }
          if (this.search.radioSearch != this.radioSearch) {
            this.search.radioSearch  = this.radioSearch
            this.search.ownerName = null
            this.search.date = ''
            this.getChats()
            return
          }
          if (this.search.selectSearch === 'name' && this.search.ownerName !== null) {
            this.search.date = ''
          }
          if (this.search.radioSearch !== 'user' && this.search.selectSearch === 'date' && this.search.date !== '') {
            this.search.ownerName = null
          }
        },
        selectDate() {
          this.$refs.vueDatePicker.selectDate()
          this.getChats()
        }
    }
  }
</script>