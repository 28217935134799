import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-vue'
import aws_exports from './aws-exports'
import customcss from './assets/custom.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

Amplify.configure(aws_exports);

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(customcss)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')
