<template>
    <DefaultLayout>
        <ManagementPage />
    </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ManagementPage from '@/components/ManagementPage.vue';
export default {   
    components: {
    DefaultLayout,
    ManagementPage
  },
}
</script>