<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-app-bar-nav-icon
        v-bind="props"
        class="main-nav-icon"
      ></v-app-bar-nav-icon>
    </template>
    <v-list class="bg-nav-menu-primary pa-2 main-hamburger-menu">
      <v-list-item
        v-for="menu in menus"
        :key="menu"
        :to="menu.href"
        :disabled="menu.name == this.$route.name ? true : false"
        :active="menu.name == this.$route.name ? true : false"
        class="item-menu"
        :class="menu.name == this.$route.name ? 'ick-active' : ''"
      >
        {{ menu.text }}
      </v-list-item>
      <template v-if="active">
        <v-list-item 
          v-for="menu in menuAdmin"
          :key="menu"
          :to="menu.href"
          :disabled="menu.name == this.$route.name ? true : false"
          :active="menu.name == this.$route.name ? true : false"
          class="item-menu"
          :class="menu.name == this.$route.name ? 'ick-active' : ''"
        >
          {{ menu.text }}
        </v-list-item>
      </template>
      <v-list-item @click="logout()">ログアウト<br><p class="text-nav">{{ currentUser ? currentUser.id : '' }}</p></v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    active: false,
    menus: [
      { text: "AI チャット", href: "/", active: true, name: "ChatView" },
      { text: "チャット履歴", href: "/chat-history", active: true, name: "ChatHistoryView" },
    ],
    menuAdmin: [
      { text: "組織・ユーザー管理", href: "/management", active: true, name: "ManagementView" },
      { text: "利用統計", href: "/usage-management", active: true, name: "UsageManagementView" },
    ],
  }),
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout")
      this.$router.push("/login")
    },
    userRole() {
      if (this.currentUser.role == "User") {
        return this.active
      }
      this.active = true

      return this.active
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.userData
    },
  },
  mounted() {
    this.userRole()
  },
};
</script>