import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { blockedKeywordByOrganizationId } from '@/graphql/queries';
import { createBlockedKeyword, updateBlockedKeyword, deleteBlockedKeyword } from '@/graphql/mutations';
export const blockedKeyword = {
    namespaced: true,
    state: { 
        blockedKeywords: []
    },
    mutations: {
        setBlockedKeywords(state, payload) {
            state.blockedKeywords = payload
        },
    },
    actions: {
        // call API getting blockedKeywords from DB
        async callApiGettingBlockedKeywords({commit}, variables = {}) {
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            const resBlockedKeywords = await API.graphql({
                query: blockedKeywordByOrganizationId,
                variables: variables,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
              });
            commit("setBlockedKeywords", resBlockedKeywords.data.blockedKeywordByOrganizationId.items)
            return {
                'items' : resBlockedKeywords.data.blockedKeywordByOrganizationId.items,
                'nextToken' : resBlockedKeywords.data.blockedKeywordByOrganizationId.nextToken
            }
        },

        async callApiCreateBlockedKeyword(_, variables = {}) {
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            return await API.graphql({
                query: createBlockedKeyword,
                variables: {input: variables},
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            })
        },

        async callApiUpdateBlockedKeyword(_, variables = {}) {
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            return await API.graphql({
                query: updateBlockedKeyword,
                variables: {input: variables},
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            })
        },

        async callApiDeleteBlockedKeyword(_, variables = {}) {
            const authSession = await Auth.currentSession()
            const jwtToken = authSession.getIdToken().getJwtToken()
            return await API.graphql({
                query: deleteBlockedKeyword,
                variables: {input: variables},
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                authToken: jwtToken
            })
        }
    },
    getters: {
        getBlockedKeywords: (state) => state.blockedKeywords
    }
}