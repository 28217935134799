<template>
    <v-dialog v-model="showDialog" class="dialog-width" @click:outside="closeDialog()">
        <v-card>
            <v-toolbar color="primary" dark>
                <v-toolbar-title class="ml-title-card">{{ view == 'edit' ? 'キーワード編集' : 'キーワード追加' }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-container class="d-none-input-form">
                    <v-row v-for="(keyword, index) in keywords" :key="index">
                        <v-col cols="11">
                            <v-text-field
                                :label="`キーワード${index > 0 ? index : ''}`"
                                variant="outlined"
                                hide-details="auto"
                                density="compact"
                                v-model="keywords[index]">
                            </v-text-field>
                            <p class="text-error">{{ error[index]?.message }}</p>
                        </v-col>
                        <v-col cols="1 lh-field" v-if="this.keywords.length > 1" @click="deleteKeywords(index)">
                            <v-icon icon="mdi-close-circle-outline cursor-pointer mdi-delete-keyword" size="x-large" disabled="true"></v-icon>
                        </v-col>
                    </v-row>
                    <v-row v-if="keywords.length < 4">
                        <v-col cols="12 text-right">
                            <v-btn variant="elevated" @click="addKeyword()" width="145">
                                <v-icon icon="mdi mdi-plus-circle-outline" class="mr-2"></v-icon>キーワード</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select 
                                variant="outlined" 
                                label="状態" 
                                v-model="status"
                                :items="selectStatusItems"
                                item-title="name" 
                                item-value="id"
                                density="compact"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-select 
                                variant="outlined" 
                                label="適用条件" 
                                v-model="condition"
                                :items="selectConditionItems" 
                                item-title="name" 
                                item-value="id"
                                density="compact"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                variant="outlined"
                                label="コメント"
                                v-model="description"
                                hide-details="auto"
                                rows="3">
                            </v-textarea>
                            <p v-for="error of v$.description.$errors" :key="error.$description">
                                <span class="text-error">{{ error.$message }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center my-3">
                <v-btn color="primary" variant="elevated" class="btn-dialog" @click="submit()" :loading="loading">{{ view == 'edit' ? '更新' : '保存' }}</v-btn>
                <v-btn class="btn-cancel" variant="elevated" @click="closeDialog()">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <WarningDialog 
        :active="showKeywordWarningDialog"
        @close="this.showKeywordWarningDialog = false" 
        :content="contentKeywordWarning" 
    />
</template>

<script>
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength } from "@vuelidate/validators"
import WarningDialog from '@/components/dialog/WarningDialog.vue'
export default {
    components: {
        WarningDialog
    },
    emits: ['close', 'resetPage', 'getBlockedKeywords', 'mutateKeyWordFail'],
    props:
    {
        active: {
            type: Boolean,
            default: false
        },
        blockedKeyModel: {
            type: Object,
            default: null
        },
    },
    setup() {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            description: {
                maxLength: helpers.withMessage(
                    () => `200桁以内で入力してください。`, maxLength(200)
                ),
            }
        }
    },
    computed: {
        showDialog() {
            return this.active
        }
    },
    data () {
        return {
            selectConditionItems: [
                { id: "Included", name: "いずれかを含む"},
                { id: "IncludeAll", name: "全て含む"}
            ],
            selectStatusItems: [
                { id: "Active", name: "有効"},
                { id: "Inactive", name: "無効"}
            ],
            id: '',
            organizationId: '',
            keywords: [''],
            status: '',
            condition: '',
            description: '',
            view: '',
            error: {},
            loading: false,
            showKeywordWarningDialog: false,
            contentKeywordWarning: `
                除外ルールの上限数を超過しています。<br>
                新しい除外ルールを追加することができません。
            `
        }
    },
    watch: {
        $props: {
            handler() {
                if (this.blockedKeyModel.view == 'edit') {
                    this.id = this.blockedKeyModel.id
                    this.keywords = this.blockedKeyModel.keywords
                    this.status = this.blockedKeyModel.status
                    this.condition = this.blockedKeyModel.condition
                    this.description = this.blockedKeyModel.description
                    this.view = this.blockedKeyModel.view
                } else {
                    this.id = ''
                    this.keywords = ['']
                    this.status = 'Active'
                    this.condition = 'Included'
                    this.description = ''
                    this.view = ''
                }
                this.organizationId = this.blockedKeyModel.organizationId
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions({
            createBlockedKeyword: "blockedKeyword/callApiCreateBlockedKeyword",
            updateBlockedKeyword: "blockedKeyword/callApiUpdateBlockedKeyword"
        }),
        addKeyword() {
            this.keywords.push('')
        },
        deleteKeywords(index) {
            this.keywords.splice(index, 1)
        },
        async submit() {
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                this.$router.push("/login");
            }
            this.error = {};
            for (let i = 0; i < this.keywords.length; i++) {
                this.keywords[i] = this.keywords[i].trim()
                if (this.keywords[i] === '') {
                    return this.error[i] = {
                        "error": "error",
                        "message": "必ず入力してください。"
                    }
                }
                if (this.keywords[i].length > 40) {
                    return this.error[i] = {
                        "error": "error",
                        "message": "40桁以内で入力してください。"
                    }
                }
                try {
                    new RegExp(this.keywords[i])
                    new RegExp("\\b"+ this.keywords[i] +"\\b");
                } catch (e) {
                    return this.error[i] = {
                        "error": "error",
                        "message": "正しい正規表現を入力してください。"
                    }
                }
            }
            const result = await this.v$.$validate();
            if (!result) {
                console.log('Invalid input');
                return;
            }
            // Check max num of blockKeyword
            let variables = {
                'organizationId': this.organizationId
            };
            let currentBlockKeyword = await this.$store.dispatch('blockedKeyword/callApiGettingBlockedKeywords', variables);
            if (currentBlockKeyword.items.length >= 20) {
                this.showKeywordWarningDialog = true;
                this.loading = false;

                return;
            }
            this.loading = true;
            try {
                let param = {
                    'organizationId': this.organizationId,
                    'keywords': this.keywords,
                    'condition': this.condition,
                    'status': this.status,
                    'description': this.description
                }
                
                if (this.blockedKeyModel.view == 'new') {
                    await this.createBlockedKeyword(param).then((res) => {
                        console.log(res)
                        this.closeDialog()
                        this.$emit("resetPage")
                        this.$emit("getBlockedKeywords")
                    })
                    return
                }
                param.id = this.id
                await this.updateBlockedKeyword(param).then((res) => {
                    console.log(res)
                    this.closeDialog()
                    this.$emit("resetPage")
                    this.$emit("getBlockedKeywords")
                })
            } catch (error) {
                this.$emit("mutateKeyWordFail", this.blockedKeyModel.view === 'new' ? 'create' : 'update');
            }
        },
        closeDialog() {
            this.loading = false;
            this.v$.$reset();
            this.$emit("close");
            this.error = {}
        },
    }
}
</script>