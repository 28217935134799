<template>
    <v-layout>
        <TopBar :displayNavMobile="displayNavMobile" @createChatAfterKnowledge="createChatAfterKnowledge" />
        <LeftNav :displayNavMobile="displayNavMobile" ref="leftNavArea" />
        <div class="toggle-nav-main-area" ref="toggleNavMainArea">
          <v-icon :icon="nmmIconToggleNav" @click="toggleNavMobile"></v-icon>
        </div>
        <v-main>
            <slot></slot>
        </v-main>
    </v-layout>
</template>

<script>
import TopBar from '@/components/chats/TopBar.vue';
import LeftNav from '@/components/chats/LeftNav.vue';

export default {
  components: {
    TopBar,
    LeftNav
  },
  data: () => ({
    nmmIconToggleNav: 'mdi-chevron-down-circle',
    displayNavMobile: 'd-none'
  }),
  methods: {
    toggleNavMobile() {
      const toggleNavMainArea = this.$refs.toggleNavMainArea;
      if(this.displayNavMobile == 'd-none') {
        this.displayNavMobile = 'd-block';
        this.nmmIconToggleNav = 'mdi-chevron-up-circle';
        toggleNavMainArea.style.top = '222px';
      } else {
        this.displayNavMobile = 'd-none';
        this.nmmIconToggleNav = 'mdi-chevron-down-circle';
        toggleNavMainArea.style.top = '64px';
      }
    },
    createChatAfterKnowledge() {
      this.$refs.leftNavArea.createChatEntity();
    }
  },
}
</script>

<style lang="scss" scoped>
.v-main {
  padding-left: 280px !important;
}
</style>