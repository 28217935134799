import { Auth } from "aws-amplify";
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { getOrganization, getUser } from '@/graphql/queries';
import store from '@/store';

export const auth = {

    namespaced: true,
    state: {
        /**
         * Cognito data
         */
        user: null, 

        /**
         * Dynamo data: User
         * Example
         *   { 
         *     id: '/ergo/999'
         *      , organizationId: 'ergp'
         *      , uid: '123'
         *      , name: 'TestAccount'
         *      ,...
         *   }
         */
        userData: null,
        /**
         * Dynamo data: Organization
         */
        organization: null,
        forgotEmail: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setUserData(state, payload) {
            state.userData = payload;
        },
        setOrganization(state, payload) {
            state.organization = payload;
        },
        setForgotEmail(state, payload) {
            state.forgotEmail = payload;
        },
    },
    actions: {
        async logout() {
            // commit("setUser", null);
            // commit("setUserData", null);
            // commit("setOrganization", null);

            let resetState = {};

            Object.entries(this.state).forEach(state => {
                const [key, childStates] = state;
                let newChildStates = {};
                Object.keys(childStates).forEach(childKey => {
                    newChildStates[childKey] = null;
                });
                resetState[key] = newChildStates;
            });
            store.replaceState(resetState);

            return await Auth.signOut();
        },
        async login({ commit }, {username, password}) {
            try {
                await Auth.signIn({
                    username,
                    password
                });

                // Getting user cognito data
                const userInfo = await Auth.currentUserInfo();
                commit("setUser", userInfo);

                const authSession = await Auth.currentSession()
                const jwtToken = authSession.getIdToken().getJwtToken()
                // Getting user data from DynamoDB
                const res = await API.graphql({
                    query: getUser,
                    variables: { id: authSession.getIdToken().payload['cognito:username'] },
                    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                    authToken: jwtToken
                });
                commit("setUserData", res.data.getUser);

                // Getting user org data from DyanamoDB
                const resOrg = await API.graphql({
                    query: getOrganization,
                    variables: { id: res.data.getUser.organizationId },
                    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
                    authToken: jwtToken
                });
                commit("setOrganization", resOrg.data.getOrganization);

                return Promise.resolve("Success");

            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async confirmSignUp(_, { username, code}) {
            try {
                await Auth.confirmSignUp(username, code);
                return Promise.resolve();

            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async signUp(_, {username, password, email}) {
            try {
                await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email
                    }
                })
                return Promise.resolve();

            } catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async authAction({commit}) {
            const userInfo = await Auth.currentUserInfo();
            commit("setUser", userInfo);
        },
        async updateUserDataKnowledges({commit, state, rootState}) {
            console.log("updateUserDataKnowledges");
            const uData = state.userData;
            uData.knowledges = rootState.knowledge.knowledges.map(el => el.id);
            commit("setUserData", uData);
        },
        async logoutIfNotInformation({commit}) {
            try {
                const currentUser = await Auth.currentUserInfo();
                // Return false value if that is ok
                if (currentUser && Object.keys(currentUser).length > 0) {
                    console.log("CurrentUser is not empty");
                    return Promise.resolve("false");
                }

                // Execute to logout (user data is empty)
                console.log("Current user data is empty");
                // Setting store data
                commit("setUser", null);
                // Logout
                await store.dispatch("logout");
                // return
                return Promise.resolve("true");
            } catch(error) {
                return Promise.resolve("false");
            }            
        }
    },
    getters: {
        user: (state) => state.user,
        userData: (state) => state.userData,
        organization: (state) => state.organization
    }
}