<template>
    <v-dialog v-model="search.modalChatHistory" max-width="768" class="dialog-add-chathistory">
        <v-card class="overflow-hidden">
            <v-toolbar color="primary" dark>
                <v-toolbar-title class="ml-title-card">{{search.chat.title}}</v-toolbar-title>
            </v-toolbar>
            <v-main class="pt-0" >
                <div class="chat-view">
                    <div class="cw-wrap-messages" id="cwWrapMessagesPaging">
                        <!-- no paging -->
                        <div v-for="message in search.messages" :key="message" class="cw-row-message pa-5" :class="message.sender !== message.ownerId ? 'message-type-answer' : 'message-type-question'">
                        <div class="cw-rm-icon">
                            <v-icon v-if="message.sender !== message.ownerId" icon="mdi-alpha-a-box" class="text-green-darken-1"></v-icon>
                            <v-icon v-else icon="mdi-alpha-u-box" class="text-blue-darken-1"></v-icon>
                        </div>
                        <div class="mt-1 cw-rm-content" :class="message.sender !== message.ownerId ? 'cw-rm-content-a' : 'cw-rm-content-q'" v-html="message.content"></div>
                        <div class="wrap-qa-time">
                            <div class="qa-time-text">{{ convertTimezone(message.createdAt, getDatetimeFormat(message.createdAt)) }}</div>
                        </div>
                        </div>
                    </div>
                </div>
            </v-main>
            <v-divider></v-divider>
            <v-card-actions class="justify-center my-3">
                <v-btn color="primary" variant="elevated" width="150px" class="btn-dialog" @click="updateModalChatHistory()">会話を続ける</v-btn>
                <v-btn class="btn-cancel" variant="elevated" @click="closeDialog()">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import Chat from '@/mixins/Chat.js'
    export default {
        mixins: [Chat],
        props: {
            modelSearch: {
                type: Object
            }
        },
        data() {
            return {
                search: this.modelSearch,
                chat_models: [
                    { state: "作成者", abbr: "name" },
                    { state: "作成日時", abbr: "date" },
                    { state: "ルール違反", abbr: "checkbox" },
                ],
                user: this.$store.getters['auth/userData']
            }
        },
        watch: {
            $props: {
                handler() {
                    this.scrollBottomMessage()
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            updateModalChatHistory() {
                this.search.modalChatHistory = false
                if (this.user.id != this.search.chat.ownerId) {
                    this.search.chat.knowledgeId = null
                    this.search.chat.aiType = ''
                    this.search.chat.aiModel = ''
                    this.search.chat.temperature = 0.7
                    this.search.chat.hasAiRole = false
                    this.search.chat.aiRoleContent = ''
                    this.search.modalDialog = true
                    this.$emit('update:modelSearch', this.search)
                    return 
                }
                this.$store.commit('chat/setActiveChat', this.search.chat)
                this.$store.commit('knowledge/setActiveKnowledge', this.search.knowledge)
                this.$router.push({ name: 'ChatView' })
            },
            closeDialog() {
                this.search.modalChatHistory = false
                this.$emit('update:modelSearch', this.search)
                this.$emit("close")
            },
            scrollBottomMessage() {
                this.$nextTick(() => {
                    if (document.getElementById("cwWrapMessagesPaging") ){
                        document.getElementById("cwWrapMessagesPaging").scrollTop = document.getElementById("cwWrapMessagesPaging").scrollHeight

                    }
                })
            }
        }
    }
</script>