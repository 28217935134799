<template>
  <v-navigation-drawer class="chat-left-nav" :permanent="$vuetify.display.smAndUp">
    <div class="cln-new-chat" @click="createChatEntity">
      <i class="mdi-plus mdi v-icon"></i>新しいチャット
    </div>
    <div class="wrap-left-nav-chats">
      <v-list>
        <v-list-item
          v-for="chat in chats"
          :key="chat.id"
          class="chat-entry-list"
          :class="chat.id == activeChat?.id ? 'active' : ''"
          @click="chooseCurrentChat(chat)"
          @mouseover="handleChatHover"
        >
          <div class="cns-share-area cel-left-icon">
            <v-btn
              variant="plain"
              density="compact"
              icon="mdi mdi-account-group-outline"
              @mouseover="isShareIconHovering[chat.id] = chat.id == activeChat?.id ? true : false"
              @mouseout="isShareIconHovering[chat.id] = false"
              @click="handdleShareIconClicked(chat)"
              :disabled="chat.id == activeChat?.id && !chat.isNew ? false : true"
              :class="{'share-active': chat.isShared === 'true', 'active': isShareIconHovering[chat.id] === true}">
            </v-btn>
          </div>
          <div class="cel-main-name">{{ chat.title }}</div>
          <div class="text-right cel-right-icon" v-if="chat.id == activeChat?.id">
            <v-btn
              v-if="!chat.isNew"
              variant="plain"
              density="compact"
              icon="mdi-pencil-outline"
              @click="editChat(chat)"
            />
            <v-btn
              variant="plain" 
              density="compact"
              icon="mdi-delete-outline"
              @click="handleClickOnDeleteIcon(chat)"
            />
          </div>
          <v-tooltip activator="parent" location="bottom" :disabled="!isShowTooltip">{{ chat.title }}</v-tooltip>
        </v-list-item>

        <ChatDialog
          ref="chatDialog"
          :active="showChatDialog"
          :chat="editingChat"
          @close="onCloseChatDialog"
          @mutateChatFail="showErrorPopup"
          @updateChatInfo="updateChatInfo"
        />

        <ConfirmDeleteDialog :active="showConfirmDeleteDialog" @close="onCloseConfirmDeleteDialog" @delete="onDelete" :is-loading="isLoading">
          <template v-slot:dialog-title>
            チャット削除の確認
          </template>
          <template v-slot:dialog-content>
            このチャットを削除しますか？<br>
            削除したチャットは復元できません。
          </template>
        </ConfirmDeleteDialog>
        <WarningDialog
          :active="showWarningDialog"
          @close="closeWarningDialog()"
          :content="contentWarning">
        </WarningDialog>
      </v-list>
    </div>
    <div class="text-user-name">
      <p>{{ currentUserData?.name ?? '' }}</p>
    </div>
  </v-navigation-drawer>

  <div class="nav-mobile-main">
    <div class="nmm-wrap-row" :class="displayNavMobile" ref="nmmWrapRowArea">
      <div class="nmm-row">
        <div class="nmm-row-l">
          <v-select
            label="チャット"
            :items="chats"
            variant="solo"
            density="compact"
            item-title="title"
            item-value="id"
            @update:modelValue="chooseCurrentChatMobile"
            v-model="activeChat"
            no-data-text="表示するデータがありません。"
          >
          <template v-slot:prepend-item>
            <v-list-item @click="createChatEntity" class="btn-add-new-mobile">
              <v-icon icon="mdi-plus"></v-icon><span>新規チャット作成</span>
            </v-list-item>
          </template>
          </v-select>
        </div>
        <div class="nmm-row-r">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn variant="plain" density="compact" icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>

            <v-list v-if="activeChat" class="chat-action-list-mobile" density="compact">
              <v-list-item v-if="!activeChat.isNew" @click="editChat(activeChat)">
                編集 <v-icon icon="mdi-pencil-outline"></v-icon>
              </v-list-item>
              <v-list-item @click="handleClickOnDeleteIcon(activeChat)">
                削除 <v-icon icon="mdi-delete-outline"></v-icon>
              </v-list-item>
              <v-list-item @click="handdleShareIconClicked(activeChat)">
                共有 <v-icon class="share-icon-mobile" icon="mdi mdi-account-group-outline" :class="{'share-active': activeChat.isShared === 'true'}"></v-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, Auth } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
// Dialog
import ChatDialog from '@/components/dialog/ChatDialog.vue';
import ConfirmDeleteDialog from '@/components/dialog/ConfirmDeleteDialog.vue';
import WarningDialog from '@/components/dialog/WarningDialog.vue';
// Queries
import { chatByKnowledgeId } from '@/graphql/queries';
// Mutations
import { deleteChatFunction } from '@/graphql/mutations';

export default {
  components: {
    ChatDialog,
    ConfirmDeleteDialog,
    WarningDialog,
  },

  props: {
    displayNavMobile: String
  },

  data: () => ({
    chats: [],
    showChatDialog: false,
    editingChat: {},
    showConfirmDeleteDialog: false,
    isLoading: false,
    isShowTooltip: false,
    isShareIconHovering: [],
    showWarningDialog: false,
    contentWarning: '',
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentUserData() {
      return this.$store.state.auth.userData;
    },
    currentOrganization() {
      return this.$store.state.auth.organization;
    },
    activeKnowledge () {
      return this.$store.getters['knowledge/getActiveKnowledge'];
    },
    activeChat () {
      return this.$store.state.chat.activeChat;
    },
    actionChat () {
      return this.$store.state.chat.actionChat;
    }
  },

  async mounted() {
    // Execute chats by activeKnowledge
    if (this.activeKnowledge !== null) {
      this.getByKnowledgeId(this.activeKnowledge.id);
    }
  },

  watch: {
    activeKnowledge(newKnowledge) {
      if (newKnowledge !== null) {
        this.getByKnowledgeId(newKnowledge.id);
      }
    },
    actionChat(newAction) {
      if (newAction == 'added' || newAction == 'updated') {
        this.getByKnowledgeId(this.activeKnowledge.id);
      }
    }
  },
  
  methods: {
    chooseCurrentChatMobile(cID) {
      var c = this.getObjectChatById(cID);
      if(c !== null) {
        this.chooseCurrentChat(c);
      }
    },

    getObjectChatById(cID) {
      for (let i = 0; i < this.chats.length; i++) {
        if(this.chats[i].id == cID) {
          return this.chats[i];
        }
      }

      return null;
    },

    createChatEntity() {
      // Setup the new chat
      const newId = 'new-' + (this.chats.length + 1);
      const newChat = {
        id: newId,
        ownerId: this.currentUser.username,
        ownerName: this.currentUserData.name,
        organizationId: this.currentOrganization.id,
        knowledgeId: this.activeKnowledge.id,
        title: '新しいチャット',
        temperature: this.activeKnowledge.temperature,
        hasAiRole: this.activeKnowledge.hasAiRole,
        aiRoleContent: this.activeKnowledge.aiRoleContent,
        isShared: this.activeKnowledge.isShared,
        aiType: this.activeKnowledge.aiType,
        aiModel: this.activeKnowledge.aiModel,
        numOfMessages: 0,
        isNew: true
      };
      this.chats.unshift(newChat);
      // Setup activedChatId
      this.activeChat = newChat;
      // Setup to store
      this.$store.commit('chat/setActiveChat', newChat)
    },

    chooseCurrentChat(currentChat) {
      // Setup activedChatId
      this.activeChat = currentChat;
      // Setup to store
      this.$store.commit('chat/setActiveChat', currentChat)
    },

    async getByKnowledgeId(knowledgeId) {
      // Current active chat
      let currentChat = this.$store.state.chat.activeChat;
      // Recreate chats
      this.chats = [];
      // Call API
      try {
        const authSession = await Auth.currentSession();
        const jwtToken = authSession.getIdToken().getJwtToken();
        var result = await API.graphql({
          query: chatByKnowledgeId,
          variables: {
            knowledgeId: knowledgeId,
            sortDirection: "DESC"
          },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          authToken: jwtToken
        });
      } catch (e) {
        console.log('get chat by knowledgeId failed: ', e);
        this.showErrorPopup('get');
      }
      // Set data to this.chats
      if (result.data.chatByKnowledgeId.items.length) {
        this.chats = result.data.chatByKnowledgeId.items;
      }
      // Set active Chat
      if (this.chats.length == 0) {
        currentChat = null;
      } else if (currentChat === null) {
        currentChat = this.chats[0];
      } else {
        const currentIndex = this.chats.findIndex((obj) => obj.id == currentChat.id);
        if (currentIndex < 0) {
          currentChat = this.chats[0];
        }
      }
      // Update store
      this.$store.commit('chat/setActiveChat', currentChat);
      this.$store.commit('chat/setActionChat', '');
    },

    editChat(currentChat) {
      this.showChatDialog = true;
      this.editingChat = currentChat;
    },

    onCloseChatDialog() {
      console.log("onCloseChatDialog");
      this.showChatDialog = false;
    },

    handleClickOnDeleteIcon(knowledge) {
      console.log(knowledge);
      this.showConfirmDeleteDialog = true;
      this.isLoading = false;
    },

    onCloseConfirmDeleteDialog() {
      this.showConfirmDeleteDialog = false;
    },

    async onDelete() {
      this.isLoading = true;
      let isSuccess = false;
      try {
        // chat is a new chat
        let isNewChat = this.activeChat.id.includes("new-") ? true : false;
        // Execute to delete chat on backend
        if (!isNewChat) {
          const authSession = await Auth.currentSession();
          const jwtToken = authSession.getIdToken().getJwtToken();
          const res = await API.graphql({
            query: deleteChatFunction,
            variables: { id: this.activeChat.id, uid: this.currentUser.username },
            authToken: jwtToken
          });
          isSuccess = res.data.deleteChatFunction.success;
        } else {
          isSuccess = true;
        }
        // Update chat list and chat store
        if (isSuccess !== false) {
          // remove deleted-chat in chats
          const toDeleteIndex = this.chats.findIndex((obj) => obj.id == this.activeChat.id);
          if (toDeleteIndex > -1) {
              this.chats.splice(toDeleteIndex, 1);
          }
          // Change active chat
          let newActiveChat = null;
          if (this.chats.length > 0) {
            newActiveChat = this.chats[0];
          }
          this.$store.commit('chat/setActiveChat', newActiveChat);
          
          this.showConfirmDeleteDialog = false;
        }
      } catch (error) {
        console.log('Error occurred');
        console.log(error);
        this.showErrorPopup('delete');
      }
    },

    handleChatHover(event) {
      const target = event.target.closest('.chat-entry-list').querySelector('.cel-main-name');
      this.isShowTooltip = target.offsetWidth < target.scrollWidth;
    },

    handdleShareIconClicked(currentChat) {
      currentChat.isShared = currentChat.isShared === 'true' ? 'false' : 'true';
      this.editingChat = currentChat;
      this.$refs.chatDialog.updateChat();
      this.isShareIconHovering[currentChat.id] = false;
    },
    
    updateChatInfo(target) {
      this.chats.some((chat,index) => {
        if(chat.id === target.id) {
          this.chats[index] = target;
          return true;
        }
      })
    },
    showErrorPopup(type) {
      this.showWarningDialog = true;
      if (type === 'update') {
          this.contentWarning = 'チャット更新に失敗しました。';
      }
      if (type === 'delete') {
          this.contentWarning = 'チャット削除に失敗しました。';
      }
      if (type === 'get') {
          this.contentWarning = 'チャットデータのアクセスに失敗しました。';
      }
    },
    closeWarningDialog() {
      this.showWarningDialog = false;
      this.contentWarning = '';
      if (this.showConfirmDeleteDialog) {
        this.showConfirmDeleteDialog = false;
        this.isLoading = false;
        return;
      }
      this.$refs.chatDialog.closeDialog();
    }
  }
}
</script>
