<template>
    <v-container>
        <v-card-title primary-title class="bd-title">
            <div class="display-title">
                <div class="w-title">除外キーワード一覧</div>
                <v-btn class="btn-add" @click="createKeyword">キーワード追加</v-btn>
                <KeywordDialog
                    ref="keywordDialogRef"
                    :blockedKeyModel="blockedKeyModel"
                    :active="showKeywordDialog"
                    @close="onCloseKeywordDialog"
                    @resetPage="resetPage"
                    @getBlockedKeywords="getBlockedKeywords"
                    @mutateKeyWordFail="showErrorPopup"
                />
            </div>
        </v-card-title>
        <v-card-title class="pl-0 text-body-1">除外キーワードに一致する内容を含むメッセージはAIへの問い合わせが制限されます。</v-card-title>
        <div class="pd-content pt-0">
            <v-data-table
                v-model:page="pageStatus.currentPage"
                :headers="headerRules"
                :items="blockedKeys"
                :items-per-page="itemsPerPage"
                :loading="isLoading"
                :no-data-text="noResultsText"
                hide-default-footer
                hover
                class="usage-management-table elevation-1 pb-5"
            >   
                <template #loading>
                    <p>{{loadingText}}</p>
                </template>
                <template v-slot:item="{ item }">
                    <tr>
                        <td class="management-rule-column">{{ item.columns.keywords.join(', ') }}</td>
                        <td>{{ enumBlockCondition[item.columns.condition] }}</td>
                        <td class="management-comment-column">{{ item.columns.description }}</td>
                        <td>
                            <v-switch 
                                class="icon-active" hide-details="auto"
                                inset v-model="item.columns.status" true-value="Active"
                                false-value="Inactive"
                                @click="updateKeywordByStatus(item.columns)"
                                color="primary">
                            </v-switch>
                        </td>
                        <td class="text-center">
                            <v-btn variant="text" class="btn-icon" @click="updateKeyword(item.columns)"><v-icon icon="mdi-pencil-outline"></v-icon></v-btn>
                            <v-btn class="btn-icon" variant="text" @click="openDeleteDialog(item.columns.id)"><v-icon icon="mdi-delete-outline"></v-icon></v-btn>
                        </td>
                    </tr>
                </template>

                <template v-slot:bottom>
                    <PaginationComponent @changePage="changPage" :pageStatus="pageStatus"/>
                </template>
            </v-data-table>
        </div>

        <ConfirmDeleteDialog :active="showDeleteDialog" @close="onCloseDeleteDialog" @delete="deleteBlockedKeyword" :is-loading="loadingConfirmDelete">
            <template v-slot:dialog-title>
                除外ルール削除の確認
            </template>
            <template v-slot:dialog-content>
                この除外ルールを削除しますか？
            </template>
        </ConfirmDeleteDialog>
        <WarningDialog 
            :active="showWarningDialog" 
            @close="closeErrorDialog()"
            :content="contentWarning" />
    </v-container>
</template>

<script>
import KeywordDialog from '@/components/dialog/KeywordDialog.vue'
import PaginationComponent from '@/components/defaults/PaginationComponent.vue'
import { mapActions } from 'vuex'
import ConfirmDeleteDialog from '@/components/dialog/ConfirmDeleteDialog.vue';
import WarningDialog from '@/components/dialog/WarningDialog.vue';

export default {
    components: {
        KeywordDialog,
        PaginationComponent,
        ConfirmDeleteDialog,
        WarningDialog,
    },
    data () {
        return {
            isLoading: false,
            loadingConfirmDelete: false,
            blockedKeys: [],
            blockedKeyModel: {},
            pageRule: 1,
            itemsPerPage: 5,
            dialogAddRule: false,
            dialogEditRule: false,
            showDeleteDialog: false,
            showKeywordDialog: false,
            organizationId: this.$store.state.auth.userData.organizationId,
            idBlockedKeyword: '',
            enumBlockCondition: {
                Included: 'いずれかを含む',
                IncludeAll: '全て含む',
                NotInclude: '含まない',
                NotIncludAll:'全てを含まない',
            }, 
            headerRules: [
            { title: '除外ルール', align: 'start', key: 'keywords', sortable: false },
            { title: '適用条件', key: 'condition', sortable: false, width: '150' },
            { title: 'コメント', key: 'description', sortable: false },
            { title: '無効 ・有効', key: 'status', sortable: false, align: 'center', width: '125' },
            { title: '操作', key: 'action', sortable: false, align: 'center', width: '125' },
            { title: 'id', key: 'id', sortable: false,  align: ' d-none'},
            { title: 'organizationId', key: 'organizationId', sortable: false,  align: ' d-none'}
            ],
            noResultsText: "表示するデータがありません。",
            pageStatus: {
                currentPage: 1,
                maxPage: null,
                isPaginationFinish: false
            },
            nextToken: null,
            loadingText: "読み込み中",
            showWarningDialog: false,
            contentWarning: ''
        }
    },
    mounted() {
        this.getBlockedKeywords()
    },
    methods: {
        ...mapActions({
            gettingBlockedKeywords: "blockedKeyword/callApiGettingBlockedKeywords",
            deletingBlockedKeyword: "blockedKeyword/callApiDeleteBlockedKeyword",
            updateBlockedKeyword: "blockedKeyword/callApiUpdateBlockedKeyword"
        }),

        async getBlockedKeywords() {
            let isCurrentUser = await this.$store.dispatch("auth/logoutIfNotInformation");
            if (isCurrentUser === 'true') {
                this.$router.push("/login");
            }
            let param = {
                organizationId: this.organizationId,
                sortDirection: "DESC",
                limit: this.itemsPerPage * 2,
                nextToken: this.nextToken
            }
            let blockedKeys = []
            this.isLoading = true
            try {
                await this.gettingBlockedKeywords(param).then((res) => {
                    blockedKeys = res.items
                    this.nextToken = res.nextToken
                })
                blockedKeys.forEach(e => {
                    this.blockedKeys.push(e)
                })
                if (blockedKeys.length !== 0) {
                    this.pageStatus.maxPage = Math.floor(this.blockedKeys.length / this.itemsPerPage)
                    this.pageStatus.maxPage = this.blockedKeys.length % this.itemsPerPage ? 
                    this.pageStatus.maxPage + 1 : this.pageStatus.maxPage
                }
                if (this.nextToken === null) {
                    this.pageStatus.isPaginationFinish = true;
                    if (this.blockedKeys.length === 0 && blockedKeys.length === 0) this.pageStatus.maxPage = 1
                }
                this.isLoading = false
            } catch (e) {
                console.log('get key word failed: ', e);
                this.showErrorPopup('get');
                this.pageStatus.isPaginationFinish = true;
                this.pageStatus.maxPage = this.pageStatus.currentPage;
            }
        },
        async deleteBlockedKeyword() {
            let param = {
                'id': this.idBlockedKeyword
            }
            this.loadingConfirmDelete = true
            try {
                await this.deletingBlockedKeyword(param).then((res) => {
                    console.log(res)
                    this.resetPage()
                    this.getBlockedKeywords()
                    this.showDeleteDialog = false
                    this.loadingConfirmDelete = false
                })
            } catch (error) {
                this.showErrorPopup('delete');
            }
        },
        createKeyword() {
            console.log("create keyword")
            this.showKeywordDialog = true
            this.blockedKeyModel = {
                organizationId: this.organizationId,
                view: 'new'
            }
        },

        updateKeyword(item) {
            let keywords = item.keywords.map((element) => {
                return element
            })
            this.blockedKeyModel = {
                id: item.id,
                keywords: keywords,
                status: item.status,
                condition: item.condition,
                description: item.description,
                organizationId: item.organizationId,
                view: 'edit'
            }
            this.showKeywordDialog = true
        },
        async updateKeywordByStatus(item) {
            try {
                let param = {
                    'id': item.id,
                    'status': item.status === 'Active' ? 'Inactive' : 'Active',
                }
                await this.updateBlockedKeyword(param).then((res) => {
                    console.log(res)
                })
            } catch (e) {
                console.log('active keyword failed: ', e);
                this.showErrorPopup('update');
            }
        },
        onCloseKeywordDialog() {
            console.log("onCloseKeywordDialog")
            this.showKeywordDialog = false
        },
        onCloseDeleteDialog() {
            console.log("onCloseKeywordDialog")
            this.showDeleteDialog = false
        },
        openDeleteDialog(id) {
            this.idBlockedKeyword = id
            this.showDeleteDialog = true
        },
        async changPage(dir) {
            if (dir === 'prev') {
                this.pageStatus.currentPage--
                return;
            }
            if (this.pageStatus.currentPage > (this.pageStatus.maxPage - 2) && this.pageStatus.isPaginationFinish === false) {
                await this.getBlockedKeywords();
            }
            this.pageStatus.currentPage++
        },
        resetPage() {
            this.blockedKeys = []
            this.pageStatus = {
                currentPage: 1,
                maxPage: null,
                isPaginationFinish: false
            }
            this.nextToken = null
        },
        showErrorPopup(type) {
            this.showWarningDialog = true;
            if (type === 'create') {
                this.contentWarning = '除外キーワード作成に失敗しました。';
            }
            if (type === 'update') {
                this.contentWarning = '除外キーワード更新に失敗しました。';
            }
            if (type === 'delete') {
                this.contentWarning = '除外キーワード削除に失敗しました。';
            }
            if (type === 'get') {
                this.contentWarning = 'キーワード一覧のアクセスに失敗しました。';
            }
        },
        closeErrorDialog() {
            this.showWarningDialog = false;
            this.contentWarning = '';
            this.isLoading = false;
            if (this.showDeleteDialog) {
                this.showDeleteDialog = false;
                this.loadingConfirmDelete = false;
                return;
            }
            this.$refs.keywordDialogRef.closeDialog();
        }
    },
}
</script>