// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    components: {
      ...components,
      ...labsComponents,
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#052a3b",
            secondary: "#677987",
            accent: "#4B4B4B",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
          }
        },
      },
    },
  }
)
