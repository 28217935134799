/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMonthlyUsageDataFunction = /* GraphQL */ `
  query GetMonthlyUsageDataFunction($input: MonthlyUsageInput) {
    getMonthlyUsageDataFunction(input: $input) {
      lastEvaluatedKey
      monthlyUsage {
        id
        targetId
        targetName
        targetType
        targetMonth
        targetService
        targetModel
        organizationId
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      status
      onwersEmailAddress
      quota {
        maxUsers
        maxChatEntries
        maxKnowledged
        __typename
      }
      selectableService {
        serviceId
        models
        modelDefault
        __typename
      }
      logoPath
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        status
        onwersEmailAddress
        quota {
          maxUsers
          maxChatEntries
          maxKnowledged
          __typename
        }
        selectableService {
          serviceId
          models
          modelDefault
          __typename
        }
        logoPath
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlockedKeyword = /* GraphQL */ `
  query GetBlockedKeyword($id: ID!) {
    getBlockedKeyword(id: $id) {
      id
      organizationId
      keywords
      condition
      description
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBlockedKeywords = /* GraphQL */ `
  query ListBlockedKeywords(
    $filter: ModelBlockedKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedKeywords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        keywords
        condition
        description
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      organizationId
      uid
      name
      email
      description
      status
      role
      owner
      knowledges
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        uid
        name
        email
        description
        status
        role
        owner
        knowledges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKnowledge = /* GraphQL */ `
  query GetKnowledge($id: ID!) {
    getKnowledge(id: $id) {
      id
      ownerId
      organizationId
      name
      description
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      isShared
      isDefault
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listKnowledges = /* GraphQL */ `
  query ListKnowledges(
    $filter: ModelKnowledgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKnowledges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        organizationId
        name
        description
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        isShared
        isDefault
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      ownerId
      organizationId
      knowledgeId
      ownerName
      title
      isShared
      aiType
      aiModel
      temperature
      hasAiRole
      aiRoleContent
      numOfMessages
      createdAt
      updatedAt
      postedAt
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      ownerId
      organizationId
      chatId
      index
      role
      sender
      temperature
      content
      finishReason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        organizationId
        chatId
        index
        role
        sender
        temperature
        content
        finishReason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMonthlyUsageStatistic = /* GraphQL */ `
  query GetMonthlyUsageStatistic($id: ID!) {
    getMonthlyUsageStatistic(id: $id) {
      id
      targetId
      targetName
      targetType
      targetMonth
      targetService
      targetModel
      organizationId
      value
      unitOfValue
      lastAggregationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMonthlyUsageStatistics = /* GraphQL */ `
  query ListMonthlyUsageStatistics(
    $filter: ModelMonthlyUsageStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonthlyUsageStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetId
        targetName
        targetType
        targetMonth
        targetService
        targetModel
        organizationId
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const blockedKeywordByOrganizationId = /* GraphQL */ `
  query BlockedKeywordByOrganizationId(
    $organizationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedKeywordByOrganizationId(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        keywords
        condition
        description
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByOrganizationId = /* GraphQL */ `
  query UserByOrganizationId(
    $organizationId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOrganizationId(
      organizationId: $organizationId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        uid
        name
        email
        description
        status
        role
        owner
        knowledges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByOrganizationIdAndUid = /* GraphQL */ `
  query UserByOrganizationIdAndUid(
    $organizationId: ID!
    $uid: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOrganizationIdAndUid(
      organizationId: $organizationId
      uid: $uid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        uid
        name
        email
        description
        status
        role
        owner
        knowledges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userOrganizationIdAndName = /* GraphQL */ `
  query UserOrganizationIdAndName(
    $organizationId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userOrganizationIdAndName(
      organizationId: $organizationId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        uid
        name
        email
        description
        status
        role
        owner
        knowledges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userOrganizationIdAndEmail = /* GraphQL */ `
  query UserOrganizationIdAndEmail(
    $organizationId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userOrganizationIdAndEmail(
      organizationId: $organizationId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        uid
        name
        email
        description
        status
        role
        owner
        knowledges
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const knowledgeByOwnerId = /* GraphQL */ `
  query KnowledgeByOwnerId(
    $ownerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKnowledgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    knowledgeByOwnerId(
      ownerId: $ownerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        name
        description
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        isShared
        isDefault
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatByOwnerId = /* GraphQL */ `
  query ChatByOwnerId(
    $ownerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatByOwnerId(
      ownerId: $ownerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatByOrganizationId = /* GraphQL */ `
  query ChatByOrganizationId(
    $organizationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatByOrganizationId(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatByOrganizationIdAndIsShared = /* GraphQL */ `
  query ChatByOrganizationIdAndIsShared(
    $organizationId: ID!
    $isSharedCreatedAt: ModelChatByOrganizationIdAndIsSharedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatByOrganizationIdAndIsShared(
      organizationId: $organizationId
      isSharedCreatedAt: $isSharedCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt = /* GraphQL */ `
  query ChatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt(
    $organizationId: ID!
    $ownerNameIsSharedCreatedAt: ModelChatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatByOrganizationIdAndOwnerNameAndIsSharedAndCreatedAt(
      organizationId: $organizationId
      ownerNameIsSharedCreatedAt: $ownerNameIsSharedCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatByKnowledgeId = /* GraphQL */ `
  query ChatByKnowledgeId(
    $knowledgeId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatByKnowledgeId(
      knowledgeId: $knowledgeId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        knowledgeId
        ownerName
        title
        isShared
        aiType
        aiModel
        temperature
        hasAiRole
        aiRoleContent
        numOfMessages
        createdAt
        updatedAt
        postedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messageByChatId = /* GraphQL */ `
  query MessageByChatId(
    $chatId: ID!
    $index: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageByChatId(
      chatId: $chatId
      index: $index
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        organizationId
        chatId
        index
        role
        sender
        temperature
        content
        finishReason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listByTargetIdAndTypeAndServiceAndModelAndMonth = /* GraphQL */ `
  query ListByTargetIdAndTypeAndServiceAndModelAndMonth(
    $targetId: ID!
    $targetTypeTargetServiceTargetModelTargetMonth: ModelMonthlyUsageStatisticByTargetIdAndTypeAndServiceAndModelAndMonthCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMonthlyUsageStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByTargetIdAndTypeAndServiceAndModelAndMonth(
      targetId: $targetId
      targetTypeTargetServiceTargetModelTargetMonth: $targetTypeTargetServiceTargetModelTargetMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetId
        targetName
        targetType
        targetMonth
        targetService
        targetModel
        organizationId
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listByTargetTypeAndServiceAndMonthAndID = /* GraphQL */ `
  query ListByTargetTypeAndServiceAndMonthAndID(
    $targetType: String!
    $targetServiceTargetMonthTargetId: ModelMonthlyUsageStatisticByTargetTypeAndServiceAndMonthAndIDCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMonthlyUsageStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByTargetTypeAndServiceAndMonthAndID(
      targetType: $targetType
      targetServiceTargetMonthTargetId: $targetServiceTargetMonthTargetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetId
        targetName
        targetType
        targetMonth
        targetService
        targetModel
        organizationId
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listByTargetTypeAndServiceAndMonthAndOrgIdAndName = /* GraphQL */ `
  query ListByTargetTypeAndServiceAndMonthAndOrgIdAndName(
    $targetType: String!
    $targetServiceTargetMonthOrganizationIdTargetName: ModelMonthlyUsageStatisticByTargetTypeAndServiceAndMonthAndOrgIdAndNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMonthlyUsageStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByTargetTypeAndServiceAndMonthAndOrgIdAndName(
      targetType: $targetType
      targetServiceTargetMonthOrganizationIdTargetName: $targetServiceTargetMonthOrganizationIdTargetName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetId
        targetName
        targetType
        targetMonth
        targetService
        targetModel
        organizationId
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listByTargetTypeAndServiceAndMonthAndOrgIdAndModelAndValue = /* GraphQL */ `
  query ListByTargetTypeAndServiceAndMonthAndOrgIdAndModelAndValue(
    $targetType: String!
    $targetServiceTargetMonthOrganizationIdTargetModelValue: ModelMonthlyUsageStatisticByTargetTypeAndServiceAndMonthAndOrgIdAndModelAndValueCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMonthlyUsageStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByTargetTypeAndServiceAndMonthAndOrgIdAndModelAndValue(
      targetType: $targetType
      targetServiceTargetMonthOrganizationIdTargetModelValue: $targetServiceTargetMonthOrganizationIdTargetModelValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetId
        targetName
        targetType
        targetMonth
        targetService
        targetModel
        organizationId
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDailyUsageStatistic = /* GraphQL */ `
  query GetDailyUsageStatistic($id: ID!) {
    getDailyUsageStatistic(id: $id) {
      id
      targetId
      targetDate
      targetType
      targetService
      targetModel
      value
      unitOfValue
      lastAggregationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDailyUsageStatistics = /* GraphQL */ `
  query ListDailyUsageStatistics(
    $filter: ModelDailyUsageStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyUsageStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetId
        targetDate
        targetType
        targetService
        targetModel
        value
        unitOfValue
        lastAggregationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
